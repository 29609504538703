import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { AppService, DataService } from '../../../../services';
import { RiskScreeningService } from '../../risk-screening.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-crypto-wallet-report',
  templateUrl: './crypto-wallet-report.component.html',
  styleUrls: ['./crypto-wallet-report.component.scss', '../../risk-screening.component.scss']
})
export class CryptoWalletReportComponent implements OnInit {
  sticky: boolean = false;
  walletData: any;
  walletMetaData: any;
  holderDetails: any;
  walletRiskScore: any;
  aliasInfo: any[] = [];
  addressInfo: any[] = [];
  isAliasExpanded: boolean = false;
  isAddressExpanded: boolean = false;
  identifierInfo: any[] = [];
  countryInfo: any[] = [];
  isIdentifierExpanded: boolean = false;
  isCountryExpanded: boolean = false;
  walletCategory: any[] = [
    { category: "Sanction", risk_type: "LOW", datasources: [] },
    { category: "Certified", tooltip: "Wallets that are not dodgy", risk_type: "LOW", datasources: [] },
    { category: "Gambling", risk_type: "LOW", datasources: [] },
    { category: "Ransomware", risk_type: "LOW", datasources: [] },
    { category: "Miner", risk_type: "LOW", datasources: [] },
    { category: "Exchange", risk_type: "LOW", datasources: [] },
    { category: "Child Sexual Abuse Material", risk_type: "LOW", datasources: [] },
    { category: "Darknet Market", risk_type: "LOW", datasources: [] },
    { category: "Dark Services", risk_type: "LOW", datasources: [] },
    { category: "Theft", risk_type: "LOW", datasources: [] },
    { category: "Scam", risk_type: "LOW", datasources: [] },
    { category: "Terrorism", risk_type: "LOW", datasources: [] },
    { category: "Coin Mixer", risk_type: "LOW", datasources: [] },
    { category: "Coin Swapping", risk_type: "LOW", datasources: [] },
    { category: "High Risk Owner", risk_type: "LOW", datasources: [] },
    { category: "Seized Assets", risk_type: "LOW", datasources: [] }
  ];
  tabs: any[] = [
    { heading: "Summary", slug: "summary" },
    { heading: "Holder Additional details", slug: "additional-info" },
    { heading: "Transaction Information", slug: "transactions-info" },
    { heading: "Screening Details", slug: "screening-details" },
    { heading: "Our top datasources", slug: "datasource-details" }
  ];
  activeTabs = "summary";
  screeningTabs: any[] = [
    { heading: "Wallet Screening", slug: "wallet-screening", icon: "/assets/images/icons/wallet.svg" },
    { heading: "Wallet Holder Screening", slug: "wallet-holder-screening", icon: null }
  ];
  activeScreeningTabs = "wallet-screening";
  amlDetailsData: any[] = [];
  topDataSourceList: any[] = [
    { name: "OFAC Ransomware Cryptocurrency Wallets", type: "Theft", matchFound: false },
    { name: "Israel NBCTF Seized Cryptocurrency Wallets", type: "Terrorism", matchFound: false },
    { name: "US OFAC Specially Designated Nationals Advanced Seized Assets", type: "Sanction", matchFound: false },
    { name: "US OFAC Specially Designated Nationals Seized Assets", type: "Sanction", matchFound: false },
    { name: "UK HMT OFSI Consolidated List of Asset Freeze Targets", type: "Sanction", matchFound: false },
    { name: "Bitcoin Gambling Wallets", type: "Gambling", matchFound: false },
    { name: "Cryptocurrency Darnet Market places", type: "Darknet Market", matchFound: false },
    { name: "Bitcoin Mining Pools", type: "Miner", matchFound: false },
    { name: "Global Bitcoin Exchanges List 1", type: "Exchange", matchFound: false },
    { name: "Bitcoin Trade and Service Companies List", type: "Certified", matchFound: false },
    { name: "Chinaabuse Cryptocurrency Scam Reports", type: "Scam", matchFound: false },
    { name: "Cryptocurrency Known Scams", type: "Scam", matchFound: false },
    { name: "Ransomwhere Ransom Reports", type: "Ransomware", matchFound: false },
    { name: "Extremist Cryptocurrency Wallets", type: "Terrorism", matchFound: false }
  ];
  transactionPageInfo = {
    itemsPerPage: 10,
    currentPage: 0,
    totalItems: 0
  };
  transactionsData: any[] = [];
  transactionTotalPage: number = 0;
  itemPerPage: any[] = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  newPage;
  isSidebarHidden: boolean = false;
  batchTableArray: any[] = [];
  currentTheme: any = 'theme-light';
  isWalletSummaryReadMore: boolean = false;
  isHolderSummaryReadMore: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(){
      const windowScroll = window.pageYOffset;
      if(windowScroll >= 6){
          this.sticky = true;
      } else {
          this.sticky = false;
      }
  }

  constructor(
    private router: Router, 
    private clipboard: Clipboard, 
    private _data: DataService,
    private _rs: RiskScreeningService,
    private _app: AppService,
  ) { }

  ngOnInit(): void {
    this._app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
    this._data.showSideBar.subscribe((res) => {
      this.isSidebarHidden = res;
    })
    if(sessionStorage.getItem('cryptoSearchResponse')){
      this.walletData = JSON.parse(sessionStorage.getItem('cryptoSearchResponse'));
      this.walletMetaData = this.walletData?.wallet_meta;

      // Transaction API Call
      this.transactionApiCall(this.walletMetaData?.wallet_address, this.transactionPageInfo?.currentPage, this.transactionPageInfo?.itemsPerPage)

      this.holderDetails = this.walletData?.holder_details;
      this.walletRiskScore = (Number(this.walletMetaData?.risk_score || 0) * 100);
      if(this.walletMetaData?.wallet_category?.length){
        this.amlDetailsData = this.walletMetaData?.wallet_category;
        this.walletCategory = this.walletCategory?.map((e) => {
          const storedObj = { ...e, };
          let obj = { ...e };
          for(let item of this.walletMetaData?.wallet_category){
            if(item?.category === e?.category){
              obj = item;
            }
          }
          return { ...storedObj, ...obj }
        });
        // Sort by match found, all match found results would appear on top
        this.walletCategory = this.walletCategory?.sort((a, b) => b?.datasources?.length - a?.datasources?.length)
      }
      if(this.walletData?.datasources?.length){
        this.topDataSourceList = this.topDataSourceList?.map((e) => {
          let obj = { ...e };
          for(let item of this.walletData?.datasources){
            if(item === e?.name){
              obj["matchFound"] = true;
            }
          }
          return obj
        })
      }
      if(this.holderDetails?.alias?.length){
        this.aliasInfo = [this.holderDetails?.alias?.[0]];
      }
      if(this.holderDetails?.address?.length){
        this.addressInfo = [this.holderDetails?.address?.[0]];
      }
      if(this.holderDetails?.identifiers?.length){
        this.identifierInfo = [this.holderDetails?.identifiers?.[0]];
      }
      if(this.holderDetails?.countries?.length){
        this.countryInfo = [this.holderDetails?.countries?.[0]];
      }
    }
    else{
      this.router.navigateByUrl('aml-screening/dashboard');
    }
  }

  transactionApiCall(address, page, size){
    this._data.changeLoaderVisibility(true);
    this._rs.doCryptoTransactionsSearch(address, page + 1, size).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.transactionsData = res?.table?.rows || [];
      this.transactionPageInfo.totalItems = res?.table?.table_meta?.totalTxn || 0;
      this.transactionTotalPage = res?.table?.table_meta?.totalPages || 0;
      this.initialNumberElement = page * size + 1;
      if (this.initialNumberElement + size > this.transactionPageInfo.totalItems) {
        this.secondElement = this.transactionPageInfo.totalItems;
      }
      else {
        this.secondElement = this.initialNumberElement + size - 1;
      }
    })
  }

  pageChange($event) {
    if($event <= 0 || $event > this.transactionTotalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.transactionPageInfo.currentPage = $event;
      this.initialNumberElement = (this.transactionPageInfo.currentPage * this.rowPageNo) + 1 - this.rowPageNo
      if (this.transactionPageInfo.currentPage * this.rowPageNo > this.transactionPageInfo.totalItems) {
        this.secondElement = this.transactionPageInfo.totalItems
      }
      else {
        this.secondElement = this.transactionPageInfo.currentPage * this.rowPageNo;
      }
      this.transactionApiCall(this.walletMetaData?.wallet_address, this.transactionPageInfo?.currentPage - 1, this.transactionPageInfo?.itemsPerPage);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.transactionPageInfo.itemsPerPage = this.rowPageNo;
    this.transactionPageInfo.currentPage = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.transactionApiCall(this.walletMetaData?.wallet_address, this.transactionPageInfo?.currentPage - 1, this.transactionPageInfo?.itemsPerPage);
  }

   // For Table Row Expand

   getIndexValueForTable(i) {
    if (this.batchTableArray.indexOf(i) > -1) {
      this.batchTableArray.splice(this.batchTableArray.indexOf(i), 1)
    }
    else {
      this.batchTableArray.push(i)
    }
  }

  aliasExpand(){
    this.isAliasExpanded = !this.isAliasExpanded;
    if(this.isAliasExpanded){
      this.aliasInfo = this.holderDetails?.alias;
    }
    else{
      this.aliasInfo = [this.holderDetails?.alias?.[0]];
    }
  }

  addressExpand(){
    this.isAddressExpanded = !this.isAddressExpanded;
    if(this.isAddressExpanded){
      this.addressInfo = this.holderDetails?.address;
    }
    else{
      this.addressInfo = [this.holderDetails?.address?.[0]];
    }
  }

  identifierExpand(){
    this.isIdentifierExpanded = !this.isIdentifierExpanded;
    if(this.isIdentifierExpanded){
      this.identifierInfo = this.holderDetails?.identifiers;
    }
    else{
      this.identifierInfo = [this.holderDetails?.identifiers?.[0]];
    }
  }

  countryExpand(){
    this.isCountryExpanded = !this.isCountryExpanded;
    if(this.isCountryExpanded){
      this.countryInfo = this.holderDetails?.countries;
    }
    else{
      this.countryInfo = [this.holderDetails?.countries?.[0]];
    }
  }

  selectedTabs(slug){
    this.activeTabs = slug;
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(slug)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    if(this.activeTabs == slug){
      let scrolly= topOfElement - 350;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else{
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  scrollDownToAml(){
    this.selectedSceeningTabs("wallet-holder-screening");
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById("screening-details")
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    let scrolly= topOfElement - 400;
    window.scroll({ top: scrolly, behavior: "smooth" });
  }

  selectedSceeningTabs(slug){
    this.activeScreeningTabs = slug;
    if(slug === "wallet-holder-screening"){
      this.amlDetailsData = this.holderDetails?.aml_category;
    }
    else{
      this.amlDetailsData = this.walletMetaData?.wallet_category
    }
  }

  goToDashboard(){
    this.router.navigateByUrl('aml-screening/dashboard');
    sessionStorage.removeItem('cryptoSearchResponse');
  }

  copyToClipBoard(str){
    this.clipboard.copy(str);
    this._data.toastrShow("Copy to clipboard", "info")
  }

}
