import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { RiskScreeningService } from '../risk-screening.service';

@Component({
  selector: 'app-global-sanction-news',
  templateUrl: './global-sanction-news.component.html',
  styleUrls: ['./global-sanction-news.component.scss', '../risk-screening.component.scss']
})
export class GlobalSanctionNewsComponent implements OnInit {
  page: number = 1;
  size: number = 10;
  order: string;
  orderArray: any[] = [
    { key: 'Latest First', value: 'desc' },
    { key: 'Oldest First', value: 'asc' },
  ]
  tableList: any[] = [];
  totalItems: number = 0;
  totalPage: number = 0;
  itemPerPage: any[] = [
    { value : 10 },
    { value : 25 },
    { value : 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  constructor(private _data: DataService, private _rs: RiskScreeningService) { }

  ngOnInit() {
    this.order = 'desc';
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  insertCssToTableList(data){
    const cssList = ['crd-1','crd-2','crd-3','crd-4','crd-5','crd-6','crd-7','crd-8','crd-9','crd-10',]
    const readVal = [1350,260,260,430,430,430,260,260,1350,1000]
    let copyDataList = data.map((eachObj,i) => Object.assign(eachObj,{cssClass:cssList[i],sliceVal:readVal[i] !== undefined ? readVal[i] : 1000}))
    return copyDataList
  }

  getTableData(page, size, order){
    const payload = {
      "page" : page ,
      "size" : size,
      "sort" : order,
      "searchType": "LOOSE",
      "searchBody": null,
      "keyword": [ "SANCTION", "SANCTIONS" ],
      "country": [],
      "publisherUrl": [],
      "startPublishDate": null,
      "endPublishDate": null
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getTrendingNews(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((news) => {
      const res = news.data
      if(res){
        const changedData = this.insertCssToTableList(res['content'])
        this.tableList = changedData || [];
        this.totalItems = res['totalElements'];
        this.totalPage = res?.totalPages;
        this.initialNumberElement = page * size + 1;
        if (this.initialNumberElement + size > this.totalItems) {
          this.secondElement = this.totalItems
        }
        else {
          this.secondElement = this.initialNumberElement + size - 1;
        }
      }
    })
  }

  orderFilter(event){
    this.order = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getTableData(this.page - 1, this.rowPageNo, this.order);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

}
