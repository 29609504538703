import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-checks-operation",
  templateUrl: "./checks-operation.component.html",
  styleUrls: ["./checks-operation.component.scss"],
})
export class ChecksOperationComponent implements OnInit {
  @Input() overriddenChecks: any;
  @Output() checksToBeOverridden = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log(this.overriddenChecks);
    this.overriddenChecks.map(obj => {
      obj.comments = null;
      obj.isSelected = false;
      return obj;
    });
  }

  updateCheckedOptions(option, e) {
    this.overriddenChecks.map(obj => {
      e.preventDefault();
      // obj.isSelected = obj.name == option.name && e.data ? true : false;
      if(option.name==obj.name){
        obj.isSelected=true;
      }
      if(option['comments']==""){
        obj.isSelected=false;
      }
      return obj;
    });
    console.log(this.overriddenChecks);
    console.log(e);
    
  }

  getSelectedChecks() {
    let checkSel = this.overriddenChecks.filter(obj => obj.isSelected == true);
    if (checkSel.length) {
      return true;
    } else {
      return false;
    }
  }

  isCheckSelected(name) {
    let isPresent = this.overriddenChecks.filter(obj => {
      if (obj.name == name && obj["isSelected"]) {
        return obj;
      }
    });
    if (isPresent.length) {
      return true;
    } else {
      return false;
    }
  }

  override() {
    let a = [];
    for (var i = 0; i < this.overriddenChecks.length; i++) {
      if (this.overriddenChecks[i].isSelected) {
        let obj = {
          endUserCheckID: null,
          overrideComment: null,
        };
        obj.endUserCheckID = this.overriddenChecks[i].data.check.endUserCheckID;
        obj.overrideComment = this.overriddenChecks[i].comments;
        a.push(obj);
      }
    }
    console.log(this.overriddenChecks);
    console.log(a);
    this.checksToBeOverridden.emit(a);
  }
}
