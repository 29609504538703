import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.scss', '../../risk-screening.component.scss']
})
export class ResultsTableComponent implements OnInit {
  tableList: any[] = []

  constructor(private router: Router) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('cryptoSearchResponse')){
      this.tableList = JSON.parse(sessionStorage.getItem('cryptoSearchResponse'))
    }
    else{
      this.router.navigateByUrl('aml-screening/dashboard');
    }
  }

  goToReport(item){
    sessionStorage.removeItem("cryptoSearchResponse")
    sessionStorage.setItem("cryptoSearchResponse", JSON.stringify(item))
    this.router.navigateByUrl('aml-screening/crypto-report')
  }

}
