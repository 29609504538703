import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskScreeningRoutingModule } from './risk-screening-routing.module';
import { RiskScreeningComponent } from './risk-screening.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  RiskDashboardTableComponent,
  RiskDashboardReportComponent,
  WidgetsComponent,
  DashboardMainComponent,
  WidgetTableComponent,
  InfoCardComponent,
  NewInvestigationComponent,
  AmlResultsMainComponent,
  AmlResultsTableComponent,
  AmlResultsAmlPageComponent,
  AmlResultsAdversePageComponent,
  DaywiseRiskWidgetComponent,
  AmlRiskInvestigationWidgetComponent,
  EntitiesStatsComponent,
  RiskScreeningTableComponent,
  RiskScreeningGuageChartComponent,
  EntityCardsComponent,
  RiskIndividualReportComponent,
  NewsTrendingComponent,
  RiskCorporateReportComponent,
  RiskPolicyTableComponent,
  GlobalSanctionNewsComponent,
  BlacklistedEntityComponent,
  ScheduledChecksComponent,
  RecentSearchesComponent,
  GlobalMapComponent,
  RecentSearcTableComponent,
  RiskEntityReportComponent,
  AdeverseMediaComponent,
  DoCorporateCheckComponent,
  RiskDashboardCompComponent,
  RiskShortTableComponent,
  CompareAmlReportComponent,
  PendingSlaAlertsComponent,
  OpenAlertsComponent,
} from './index';
import { FamilyTree } from './risk-individual-report/family-tree/app-family-tree.component';
import { RecursiveComponent } from './risk-individual-report/family-tree/recursive-component/recursive-component.component';
import { FormsModule } from '@angular/forms';
import { AmlStatusColorDirective } from './aml-status-color.directive';
// import { GaugeChartModule } from 'angular-gauge-chart';
import { ComponentModuleModule } from '../../components/component-module/component-module.module';
// import { NgxOrgChartModule } from 'ngx-org-chart';
import { GetParseData } from './get-parse-data.pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AmlSearchModule } from '../aml-search/aml-search.module';
import { PipesModule } from '../../../app/pipes/pipes.module';
import { AdvanceSearchComponent } from './advance-search/advance-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxGraphModule } from "@swimlane/ngx-graph";
import { MyComponentWrapperComponent } from './test-chart/textwrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AmlResultsMainCorpIndiaComponent } from './aml-results-main-corp-india/aml-results-main-corp-india.component';
import { AmlResultsAdversePageCorpIndiaComponent } from './aml-results-main-corp-india/aml-results-adverse-page-corp-india/aml-results-adverse-page-corp-india.component';
import { AmlResultsAmlPageCorpIndiaComponent } from './aml-results-main-corp-india/aml-results-aml-page-corp-india/aml-results-aml-page-corp-india.component';
import { AmlResultsTableCorpIndiaComponent } from './aml-results-main-corp-india/aml-results-table-corp-india/aml-results-table-corp-india.component';
import { DoCorporateCheckCorpIndiaComponent } from './aml-results-main-corp-india/do-corporate-check-corp-india/do-corporate-check-corp-india.component';
import { RiskEntityReportCorpIndiaComponent } from './risk-entity-report-corp-india/risk-entity-report-corp-india.component';
import { RiskIndividualReportCorpIndiaComponent } from './risk-individual-report-corp-india/risk-individual-report-corp-india.component';
import { RiskCorporateReportCorpIndiaComponent } from './risk-corporate-report-corp-india/risk-corporate-report-corp-india.component';
import { ResultsTableComponent } from './crypto-stuff/results-table/results-table.component';
import { CryptoWalletReportComponent } from './crypto-stuff/crypto-wallet-report/crypto-wallet-report.component';

@NgModule({
  declarations: [
    RiskScreeningComponent,
    RiskDashboardTableComponent,
    RiskDashboardReportComponent,
    DashboardMainComponent,
    WidgetsComponent,
    WidgetTableComponent,
    InfoCardComponent,
    AmlStatusColorDirective,
    NewInvestigationComponent,
    AmlResultsMainComponent,
    AmlResultsTableComponent,
    AmlResultsAmlPageComponent,
    AmlResultsAdversePageComponent,
    DaywiseRiskWidgetComponent,
    AmlRiskInvestigationWidgetComponent,
    EntitiesStatsComponent,
    EntityCardsComponent,
    RiskScreeningTableComponent,
    RiskScreeningGuageChartComponent,
    RiskIndividualReportComponent,
    NewsTrendingComponent,
    RiskCorporateReportComponent,
    RiskPolicyTableComponent,
    GlobalSanctionNewsComponent,
    BlacklistedEntityComponent,
    ScheduledChecksComponent,
    RecentSearchesComponent,
    GetParseData,
    FamilyTree,
    RecursiveComponent,
    GlobalMapComponent,
    RecentSearcTableComponent,
    RiskEntityReportComponent,
    AdvanceSearchComponent,
    AdeverseMediaComponent,
    MyComponentWrapperComponent,
    DoCorporateCheckComponent,
    RiskDashboardCompComponent,
    RiskShortTableComponent,
    CompareAmlReportComponent,
    PendingSlaAlertsComponent,
    OpenAlertsComponent,
    AmlResultsMainCorpIndiaComponent,
    AmlResultsAdversePageCorpIndiaComponent,
    AmlResultsAmlPageCorpIndiaComponent,
    AmlResultsTableCorpIndiaComponent,
    DoCorporateCheckCorpIndiaComponent,
    RiskEntityReportCorpIndiaComponent,
    RiskIndividualReportCorpIndiaComponent,
    RiskCorporateReportCorpIndiaComponent,
    ResultsTableComponent,
    CryptoWalletReportComponent,
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    RiskScreeningRoutingModule,
    FormsModule,
    NgxPaginationModule,
    // GaugeChartModule,
    ComponentModuleModule,
    // NgxOrgChartModule,
    BsDropdownModule.forRoot(),
    AmlSearchModule,
    PipesModule,
    NgSelectModule,
    NgxSliderModule,
    NgxGraphModule,
    InfiniteScrollModule
  ],
  exports: [
    RiskDashboardTableComponent,
    AmlStatusColorDirective
  ]
})
export class RiskScreeningModule { }
