import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../services';

@Component({
  selector: 'app-new-aml-results',
  templateUrl: './new-aml-results.component.html',
  styleUrls: ['./new-aml-results.component.scss']
})
export class NewAmlResultsComponent implements OnInit {
  @Input() data: any;
  @Input() entityName: string;
  activabs: any = 'pep';
  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalItems: number;
  maxSize: number;
  startIndex: number = 0;
  endIndex: number = 10;
  pageList: any = [];
  buildQuery: any = {
    'size': this.itemsPerPage,
    'page': this.currentPage
  };

  constructor(private app: AppService) { }

  tabs: any[] = [
    {
      name: 'PEP',
      slug: 'pep',
      data: [],
    },
    {
      name: 'Sanction',
      slug: 'sanction',
      data: [],
    },
    {
      name: 'Crime',
      slug: 'crime',
      data: [],
    },
    {
      name: 'SIP',
      slug: 'sip',
      data: [],
    },
    {
      name: 'Watchlist',
      slug: 'watchlist',
      data: [],
    },
    {
      name: 'Adverse Media',
      slug: 'adverse-media',
      data: null,
    },
  ];
  dataSourceName : any[] = [
    {
      slug: 'pep',
      list: [
        { name: "Every politicians" },
        { name: "House of commons" },
        { name: "List of world leaders" },
        { name: "FRANCE SENATE LIST" },
        { name: "United Kingdom Government People" },
        { name: "Nevada Attorney General" },
        { name: "UNOPS leaders" },
        { name: "US SECURITIES AND EXCHANGE COMMISSION COMMISSIONERS" },
        { name: "Presidents of the Senate" },
        { name: "Ministers of South Africa" },
        { name: "Japan Ministers List" },
        { name: "Minister of Law and Justice" },
        { name: "Senate Of US State Wise" },
        { name: "Prime Ministers Office Singapore Cabinet Ministers" },
        { name: "List Of 11th Parliament members of Bangladesh" },
        { name: "Federal Ministers Russian Government" },
        { name: "House of Lords" }
      ]
    },
    {
      slug: 'watchlist',
      list: [
        {name: 'Nyse Disciplinary Actions'},
        {name: '2019 Delinquent Child Support List'},
        {name: 'Occ Enforcement Action'},
        {name: 'Data Mega Store'},
        {name: 'EBRD'},
        {name: 'Canada Alberta Securities Commission Decisions And Orders'},
        {name: 'British Columbia Securities Commission Notices Of Hearing And Temporary Orders'},
        {name: 'Security Commission Malaysia Investor Alert List'},
        {name: 'Osc Tribunal Proceedings'},
        {name: 'Monetory Authority Of Singapore Investor Alert List'},
        {name: 'Statutorily Debarred Parties'}
      ] 
    },
    {
      slug: 'sanction',
      list: [
        { name: 'EU Sanctions' },
        { name: 'UK HM treasury' },
        { name: 'US department of Treasury' },
        { name: 'OFAC' },
        { name: 'SECO Sanctions List' },
        { name: 'UK SANCTIONS ON RUSSIAN BANKS AND INDIVIDUALS' },
        { name: "OFAC Lists" },
        { name: "World Bank Ineligible Firms Individuals" },
        { name: "UK Sanctions List Publication" },
        { name: "US Treasury Designated Russian Oligarchs" },
        { name: "EU Sanction Individual 09102021" },
        { name: "UK HM Treasury New" },
        { name: "Ukraine Russia related Sanctions" },
        { name: "Canada Economic Measures Russia-1" },
        { name: "Canada Economic Measures Russia-2" },
        { name: "UK HM Treasury" },
        { name: "EU Sanction" },
        { name: "US Department Of The Treasury SDN" },
        { name: "Interpol UN Notices Entities" },
        { name: "SECO" },
        { name: "UK GOV SANCTIONS" },
        { name: "CIBIL's List of Wilful Defaulters" },
        { name: "FinCEN 311 Special Measures" },
        { name: "Willful Defaulters" },
        { name: "Suspended and Blacklisted NGOs" },
        { name: "Tax Defaulters Individuals" }
      ]
    },
    {
      slug: 'crime',
      list: [
        { name: "Interpol Public Red Notices" },
        { name: "DEA'S MOST WANTED" },
        { name: "FBI Wanted Terrorists" },
        { name: "ICAC Wanted Persons" },
        { name: "US Department of Transportation" },
        { name: "U.S. Department of Justice" },
        { name: "INTERPOL RED NOTICE" },
        { name: "Singapore Terrorism Suppression of Financing Act Order 2015" },
        { name: "ATF Most Wanted" }
      ]
    },
    {
      slug: 'sip',
      list: [
        { name: "List Issued By Fcnb Agent" },
        { name: "Richest Models" },
        { name: "Richest Authors" },
        { name: "Monetory Authority Of Singapore Payments" },
        { name: "Federal Deposit Insurance Corporation Members" },
        { name: "Hudson County Most Wanted" },
        { name: "List Of Corporate Headquarters In Mbabane" },
        { name: "The Most Famous Celebrity Born Every Year Since 1975" },
        { name: "Fcnb Mortage Brokers And Associates" },
        { name: "Swazi Celebrities" }
      ]
    }
  ];
  ngOnInit() {
    if(this.data){
      if(this.data && this.data.amlProcessedInfo &&  this.data.amlProcessedInfo.amlProcessedInfo){
        const response =  this.data.amlProcessedInfo.amlProcessedInfo.endUserAmlChecks;
        this.getAmlData(response);
      }
      else{
        const index = this.tabs.findIndex((e) => e.slug == 'adverse-media');
        if(index > -1){
          let adverseTab = this.tabs[index];
          this.tabs = [];
          this.tabs.push(adverseTab);
          this.activabs = 'adverse-media';
        }
      }
      if(this.data && this.data.adverseMediaProcessedInfo2){
        const response = this.data.adverseMediaProcessedInfo2.adverseMediaResults.results
        this.getAdverseData(response);
      }
      else{
        const index = this.tabs.findIndex((e) => e.slug == 'adverse-media');
        if(index > -1){
          this.tabs.splice(index, 1);
        }
      }
    }
  }

  getAmlData(response){
    if(response.length){
      for(let res of response){
        if(res.amlVerificationResult){
          const parseData = JSON.parse(res.amlVerificationResult);
          if(parseData.data && parseData.data.content && parseData.data.content.length){
            if(parseData.data.content[0].details && parseData.data.content[0].details.length){
              for(let item of parseData.data.content[0].details){
                // this.guid = item.guid
                if(item.category && item.category.length){
                  for(let cat of item.category){
                    this.tabs.forEach((e) => {
                      if(e.slug == cat.slug){
                        e.data.push(item)
                      }
                    })
                  }
                }
              }
            }
          }
        }
      }
      this.tabs.forEach((e) => {                      
        this.dataSourceName.forEach((s) => {
          if(s.slug == e.slug){
            let dataSourceArray = [];
              for(let source of e.data){
                if(source.datasource && source.datasource.dataAgencyName){
                  dataSourceArray.push(source.datasource.dataAgencyName.toUpperCase())
                }
              }
              s.list.forEach((match) => {
                if(dataSourceArray.includes(match.name.toUpperCase())){
                  match['source'] = 'Record Found'
                }
                else{
                  match['source'] = 'No Records Found'
                }
              })
            e['sourceNameList'] = s.list
          }
        })
      })
    }
  }

  getAdverseData(response){
    if(response && response.content && response.content.length){
      this.tabs.forEach((e) => {
        if(e.slug == 'adverse-media'){
          e.data = response.content;
        }
      })
    }
    // this.getAdverseArticles(this.buildQuery);
  }

  getAdverseArticles(buildQuery){
    this.tabs.forEach((e) => {
      if(e.slug == 'adverse-media'){
        const payload = {
          "entityName": this.entityName.trim().replace(/ +/g, ' '), // removing extra spaces from the name
          "page": buildQuery.page,
          "size": buildQuery.size
        }
        this.app.getNewAdverseArticles(payload).subscribe((res) => {
          if(res && res.source && res.source.length){
            const arr = res.source
            for(let item of arr){
              item['adverseMediaResult'] = item
            }
            e.data = arr;
          }
          this.totalItems = res.nrOfElements;
          this.maxSize = res.pageCount;
          this.pageSize()
        })
      }
    })
  }

  getPaginatedText() {
    let a = (this.currentPage * this.itemsPerPage) + 1;
    let b;
    if (this.currentPage < (this.maxSize - 1)) {
      b = (this.currentPage + 1) * this.itemsPerPage;
    }
    else if (this.currentPage == (this.maxSize - 1)) {
      b = this.totalItems;
    }
    return a + "-" + b;
  }

  getPaginatedData(isNext) {
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    this.buildQuery["page"] = isNext ? this.buildQuery["page"] + 1 : this.buildQuery["page"] - 1;
    if (this.currentPage < this.maxSize) {
      this.getAdverseArticles(this.buildQuery)
      this.pageSize()
    }
  }
  getPagedData(index) {
    this.currentPage = index
    this.buildQuery["page"] = index;
    if (this.currentPage < this.maxSize) {
      this.getAdverseArticles(this.buildQuery)
      this.pageSize()
    }
  }

  getPage(length) {
    return (Math.round(length / 10));
  }
  
  pageSize() {
    this.pageList = []
    let totalPages = this.getPage(this.totalItems)
    this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
    this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
    for (let i = 0; i <= this.currentPage + 4; i++) {
      if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
    }
  }

  selecteTab(tab){
    this.activabs = tab;
  }

  getAdverseMediaNewImage(data){
		if(data){
			return data ;
		}
		else{
			return "assets/images/profile-picture.png" ;
		}
  }

}
