import * as React from 'react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { RadialGraph } from '@ant-design/graphs';
import { environment } from '../../../../environments/environment';
// import './MyReactComponent.scss';
// import { environment } from "../../environments/environment";
import { ToastContainer, toast } from 'react-toastify';

export interface IMyComponentProps {
  counter: number;
  onClick?: () => void;
  data: any

}
const color = {
  "hint": "#98a6ad",
  "text": "rgba(255, 255, 255, 1)"
}

export const MyReactComponent: FunctionComponent<IMyComponentProps> = (props: IMyComponentProps) => {
  const timerHandle = useRef<number | null>(null);
  const [stateCounter, setStateCounter] = useState(42);
  const RadialData = {
    nodes: [
      {
        id: '0',
        label: '0',
        value: {
          text: 'Company1',
          // 建议使用 bae64 数据
          icon: 'https://gw.alipayobjects.com/zos/antfincdn/28B4PgocL4/bbd3e7ef-6b5e-4034-893d-1b5073ad9aa4.png',
        }
      },
      {
        id: '1',
        label: '1',
      },
      {
        id: '2',
        label: '2',
      },
      {
        id: '3',
        label: '3',
      },
      {
        id: '4',
        label: '4',
      },
      {
        id: '5',
        label: '5',
      },
      {
        id: '6',
        label: '6',
      },
      {
        id: '7',
        label: '7',
      },
      {
        id: '8',
        label: '8',
      },
      {
        id: '9',
        label: '9',
      },
    ],
    edges: [
      {
        source: '0',
        target: '1',
        value: "test"


      },
      {
        source: '0',
        target: '2',
      },
      {
        source: '1',
        target: '3',
      },
      {
        source: '0',
        target: '4',
      },
      {
        source: '0',
        target: '5',
      },
      {
        source: '0',
        target: '6',
      },
      {
        source: '1',
        target: '7',
      },
      {
        source: '1',
        target: '5',
      },
      {
        source: '0',
        target: '8',
      },
      {
        source: '0',
        target: '9',
      },
    ],

  };
  const [radialData, setRadialData] = useState<any>(null)
  useEffect(() => {

    // timerHandle.current = +setInterval(() => {
    //   setStateCounter(stateCounter + 1);
    // }, 2500);
    // return () => {
    //   if (timerHandle.current) {
    //     clearInterval(timerHandle.current);
    //     timerHandle.current = null;
    //   }
    // };
    (async () => {
      const amlSearchQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'))
      console.log(12, amlSearchQuery)
      const data = await fetchRadialData(amlSearchQuery?.companyId, amlSearchQuery?.investigationType)
      setRadialData(data)
    })()

  }, []);
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const fetchRadialData = async (id, type) => {
    console.log(id, type)
    if (id && type) {
      const url = `${environment.apiUrl}/corporate/uk/graph/${type}/${id}`
      try {
        let access_token = getCookie("token");
        const res = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`

          }
        });
        const getSlashN = (length) => {
          const str = Array(length).fill('\n').join('');
          return str;
        }
        const getPrefix = (name) =>{
          const l = name?.split(' ')?.length;
          if(l%2 == 0){
            return getSlashN(7 - l)
          }
          else{
            return getSlashN(8 - l)
          }
        }
        const getSuffix = (name) =>{
          const r = name?.split(' ')?.length;
          if(r%2 == 0){
            return getSlashN(8 - r)
          }
          else{
            return getSlashN(7 - r)
          }
        }
        const data = await res.json();
        return { edges: data.edges?.map((e) => ({ ...e, value: capitalizeWords(e?.info?.ownerShip) })), nodes: data.nodes.map(e => ({ ...e, edgeStatus: data.edges?.find((fn) => fn?.source == e?.id)?.info?.status, label: e?.countryOfOrigin ? (getSuffix(e?.name) + e?.name?.split(" ").join("\n") + getPrefix(e?.name) + e?.countryOfOrigin) : (e?.name?.split(" ").join("\n")  || 'Undisclosed entity'), root: e.id === id })) }

      } catch (error) {
        //
        console.log(error)
      }
    }
  }
  const { counter: propsCounter, onClick, data: parentData } = props;
  const getSortedStr = (a, b) => {
    if (a > b) {
      return a + b
    }
    else {
      return b + a
    }
  }
  const formatRadialData = (data) => {

    const fData = {
      edges: [...data.edges.map(e => ({ source: e.value + getSortedStr(e.target, e.source), target: e.target })), ...data.edges.map(e => ({ source: e.source, target: e.value + getSortedStr(e.target, e.source) }))],
      nodes: [...data.nodes, ...data.edges.map(e => ({ label: e.value, id: e.value + getSortedStr(e.target, e.source), type: 'conjunctionNode' }))]
    }
    console.log(data, fData)
    return fData
  }

  const chartRef: any = useRef();

  function capitalizeWords(input) {
    if(input?.length){
      const splitData = input?.length > 1 ? input?.join(',') : input?.[0];
      return splitData
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    else{
      return ''
    }
  }


  const fetchData = (node, data) => {
    console.log(97, node, data)
    return new Promise((resolve, reject) => {
      console.log('2', data)
      setTimeout(() => {
        console.log('3', data)
        resolve({
          nodes: [

          ].concat(
            data.nodes,
          ),
          edges: data.edges,
        });
      }, 0);

      console.log('4', data)
    });
  };

  const getNodeBg = (i) => {
    console.log("tytpe", i.type.toLowerCase())
    if (i.root) {
      return "#475e6c"
    }
    else {
      switch (i.type.toLowerCase()) {
        case "individual":
          return "#006E47"
        case "organisation":
          return "#0068AA"
        case "traceableinfo":
          return "#b70000"
        case "conjunctionnode":
          return "transparent"
        default:
          return "#20c997"
      }
    }
  }

  const asyncData = async (node) => {
    if(node?.type != 'TraceableInfo'){
      const res: any = await fetchRadialData(node.id, node.type);
      let resData = JSON.parse(JSON.stringify(res));
      if(node?.id && resData?.nodes?.length){
        const nodeObj = resData?.nodes?.find((e) => e?.id == node?.id);
        if(nodeObj?.message){
          let message = nodeObj?.message;
          let within_parentheses = '';
          let outside_parentheses = '';
          if (message.includes('(') && message.includes(')')) {
            let start_index = message.indexOf('(');
            let end_index = message.indexOf(')');
            within_parentheses = message.slice(start_index + 1, end_index);
            outside_parentheses = message.slice(0, start_index) + message.slice(end_index + 1);
          } 
          else {
            outside_parentheses = message;
            within_parentheses = message;
          }
          resData['nodes'].push({ id: within_parentheses?.split(" ").join("\n"), label: within_parentheses?.split(" ").join("\n"), name: within_parentheses, root: false, status: null, type: 'TraceableInfo' });
          resData['edges'].push({ info: null, target: nodeObj?.id, source: within_parentheses?.split(" ").join("\n"), value: outside_parentheses });
          toast(nodeObj?.message);
        }
      }
      return await fetchData(node, resData);
    }
    else{
      return { nodes: [], edges: [] };
    }
  };

  const config = {
    data: radialData,
    autoFit: false,
    fitCenter: true,
    style: {
      height: '90vh',
      backgroundColor: 'transparent',
    },
    layout: {
      unitRadius: 200,
      /** 节点直径 */
      nodeSize: 10,
      /** 节点间距 */
      // nodeSpacing: 100,
      preventOverlap:true,
      strictRadial: false,
      // maxIteration: 10,
      linkDistance:400,
      nodeSpacing:100
 

    },
    nodeCfg: {
      asyncData,
      size: [100],
      // style: {
      //   fill: '#6CE8DC',
      //   stroke: '#6CE8DC',
      // },
      style: (item) => {
        const bool = item.type === "conjunctionNode";
        return {
          fill: getNodeBg(item),
          stroke: bool ? 'transparent' : (item?.edgeStatus?.toLowerCase() == 'active' ? 'rgb(23, 177, 140)' : ['ceased', 'withdrawn']?.includes(item?.edgeStatus?.toLowerCase()) ? 'rgb(225, 40, 40)' : '#fff'),


          lineWidth: bool ? 0 : 2,





        };
      },
      labelCfg: {
        style: {
          fontSize: 11,
          fill: color.text,




        },
      },








      padding: 4,
      nodeStateStyles: {
        hover: {
          lineWidth: 1,
          stroke: "#0075ff"
        },

      },


    },
    menuCfg: {
      customContent: (e) => {
        return (
          <div>
            <button
              onClick={() => {
                chartRef.current.emit('node:click', e);
              }}
            >
              test
            </button>
          </div>
        );
      },
    },

    edgeCfg: {

      style: {
        lineWidth: 2,
        stroke: color.hint,
        strokeOpacity:0.5

      },
      endArrow: {
        d: 30,
        size: 0,
        show: false


      },

      startArrow: {
       
        d: 50,
        size: 4,
        show: true
      },
      edgeStateStyles: {
        hover: {
          strokeOpacity: 1,
        },
      },

      label: {
       
        style: {
          fill: "#fff",
          fontSize: 10,
          offset:2,
          shadowColor: "#fff",
          shadowBlur: 6,
          padding:12,
     
      
         
        
        
        }
      }
    },
    toolbarCfg: {
      show: true
    },
    minimapCfg: {
      show: false
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
    onReady: (graph) => {
      chartRef.current = graph;
      graph.on('node:click', (evt) => {
        console.log(99, evt.item);
      });
      // graph.on('node:dblclick', (evt) => {
      //   console.log(98,evt);
      // });
    },

  };



  return (
    <div className="App">
      {
        radialData && radialData.edges && radialData.edges.length ? <><RadialGraph style {...config} /></> : <><div className='hint-text' style={{fontStyle:"italic"}}>NO RELATION DATA FOR THIS ENTITY</div></>
      }
      <ToastContainer hideProgressBar={true} />
    </div>
  );
};