import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { AppService, DataService } from '../../services';
import { KycModalComponent } from '../../shared/kyc-modal/kyc-modal.component';

@Component({
  selector: 'app-risk-aml-summary',
  templateUrl: './risk-aml-summary.component.html',
  styleUrls: ['./risk-aml-summary.component.scss']
})
export class RiskAmlSummary implements OnInit {
  @Input() amlSummary: any;
  @Input() amlType: any;
  @Input() categoryName?: any;
  @Input() markFalsePositive?: boolean;
  showHideDetailsArray: any[] = [];
  public bsModalRef: BsModalRef;
  constructor(private _app: AppService, private _data: DataService, private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  markAsFalsePositive(item){
    const initialState = {
      title: `Mark as false positive`,
      type: "mark-as-false-positive",
      markAsFalsePositiveData : {
        name : item.fullName,
        reporting : this.categoryName,
        source : (item.datasource) && (item.datasource.slug.includes('data-mega-store') ? 'Others' : item.datasource.dataAgencyName)
      }

    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        const payload = {
          "entityName": item.fullName,
          "entityGuid": item.guid,
          "comment": result.comment,
          "falsePositiveStatus": "FALSE_POSITIVE",
          "amlData" : item, 
        }
        this._data.changeLoaderVisibility(true);
        this._app.amlMarkAsFalsePositive(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe();
      }
    })
  }

  toggleDetails(index){
    const i = this.showHideDetailsArray.indexOf(index);
    if(i > -1){
      this.showHideDetailsArray.splice(i, 1);
    }
    else{
      this.showHideDetailsArray.push(index);
    }
  }

}
