import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services';
import { RiskScreeningService } from '../../risk-screening.service';

@Component({
  selector: 'app-aml-results-aml-page-corp-india',
  templateUrl: './aml-results-aml-page-corp-india.component.html',
  styleUrls: ['./aml-results-aml-page-corp-india.component.scss', '../../risk-screening.component.scss']
})
export class AmlResultsAmlPageCorpIndiaComponent implements OnInit {
  entityAmlData: any;
  countryObject: any;
  entityMasterData: any;
  riskProfileData: any;
  amlArray: any[] = [];
  corpAmlUseArray: any[] = [];
  categoryList = [{ "slug": "pep", "name": "PEP", "value": false, entity: "Individual" }, { "slug": "crime", "name": "Crime", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sanction", "name": "Sanction", "value": false, entity: "Individual,Organisation,Entity" }, { "slug": "watchlist", "name": "Watchlist", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sip", "name": "SIP", "value": false, entity: "Individual" }, { "slug": "sie", "name": "SIE", "value": false, entity: "Organisation,Entity" },
  { "slug": "si", "name": "SI", value: false, entity: "Individual,Organisation,Entity" }
  ];
  companyType: any;
  queryParams: any;
  constructor(private _rs: RiskScreeningService, private _data: DataService, private route: Router,private _aRoute: ActivatedRoute) {
    this._aRoute.queryParams.subscribe(params => {
      this.companyType = params['companyType'];
      this.queryParams = params
  });
  }

  topData =  [];
  async ngOnInit() {
    if (sessionStorage.getItem('entityAmlData')) {
      this.entityAmlData = JSON.parse(sessionStorage.getItem('entityAmlData'));
      if (this.entityAmlData.masterData) {
        this.entityMasterData = this.entityAmlData.masterData;
      }
      if (this.entityAmlData.guid) {
        this.getCrrData(this.entityAmlData.guid);
      }
      if (this.entityMasterData.nationality) {
        this.getCountryObject(this.entityMasterData.nationality[0]);
      }
      else if (this.entityMasterData.countryOfCitizenship) {
        this.getCountryObject(this.entityMasterData.countryOfCitizenship[0]);
      }
      this.categoryList = this.categoryList.filter((e) => e.entity.includes(this.entityMasterData.entityType));
      // Aml Data
      if (this.entityAmlData.details && this.entityAmlData.details.length) {
        this.amlArray = this.categoryList.map((e) => (
          {
            categoryName: e.name, entityType: this.entityMasterData.entityType,
            details: this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1)
              .map((m) => ({ ...m, dateAdded: this.entityAmlData.recordCreatedDate, lastModified: this.entityAmlData.recordLastModifiedDate })),
            dataSource: JSON.stringify(this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1)
              .map((m) => m.datasource && m.datasource.name))
          }
        ));
        for (var j = 0; this.amlArray.length > j; j++) {
          this.getIndexValueForCorpAML(j);
        }
      }
      if (this.amlArray.length > 0) {
        let countrytemp = (this.entityMasterData.countryOfCitizenship && this.entityMasterData.countryOfCitizenship[0]) || (this.entityMasterData.nationality && this.entityMasterData.nationality[0]);
        try {
          for (let i = 0; i < this.amlArray.length; i++) {
            this._data.changeLoaderVisibility(true);
            await this.getTopDataSourcesAml(this.amlArray[i].dataSource, this.amlArray[i].categoryName, countrytemp);
            this._data.changeLoaderVisibility(false);
          }
          sessionStorage.setItem('topData',JSON.stringify(this.topData))
        } catch (error) {
          console.error("Error in sequential API calls:", error);
        }
      }
    }
    else {
      this.route.navigate([`/aml-screening/dashboard`], {
        replaceUrl: true,
      });
    }
  }

  async getTopDataSourcesAml(datasource, category, country) {
    let payload = {
      category: category.toLowerCase(),
      country: country,
      global: category.toLowerCase() === "sanction" ? true : false,
    };
  
    try {
      let response = await this._rs.getTopDataSource(payload).toPromise();
  
      if (response && (response.length === 0 || response === null)) {
        payload.global = true;
        response = await this._rs.getTopDataSource(payload).toPromise();
      }
  
      if (response) {
        const Data = await this.updateFoundStatus(response, datasource);
        this.topData.push(Data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  async updateFoundStatus(Data, dataSource): Promise<void> {
    const response = JSON.parse(dataSource);
    for (const e of Data) {
      try {
        let foundStatus = response
          .map((item) => item.toLowerCase())
          .includes(e.name.toLowerCase());
        e["found"] = foundStatus;
      } catch (error) {
        console.error("Error updating found status:", error);
      }
    }
    return Data
  }

  // For Auto Expand

  getIndexValueForCorpAML(i) {
    if (this.corpAmlUseArray.indexOf(i) > -1) {
      this.corpAmlUseArray.splice(this.corpAmlUseArray.indexOf(i), 1)
    }
    else {
      this.corpAmlUseArray.push(i)
    }
  }

  getCountryObject(country) {
    this._rs.getCountryDetailsByCountry(country).subscribe((res) => this.countryObject = res['data']);
  }

  getCrrData(guid) {
    const payload = {
      guid: guid
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getAdvMediaResults(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if (res['data'] && res['data']['content'] && res['data']['content'].length) {
        this.riskProfileData = res['data']['content'][0].riskProfile;
      }
    })
  }

  goToTable() {
    this.route.navigate(['/aml-screening/aml-investigation-results-corp-india/table'], {
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  goToAdversePage() {
    this.route.navigate(['/aml-screening/aml-investigation-results-corp-india/adverse'], {
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  async goToReport() {
    //moved the aml investigation create here rather than on their individual comps bcz of xsrf issue:

    await this.createAmlInvestigation(this.entityMasterData.entityType)
    if (this.entityMasterData.entityType == 'Individual') {

      this.route.navigate(['/aml-screening/risk-individual-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
    else if (this.entityMasterData.entityType == 'Entity') {
      this.route.navigate(['/aml-screening/risk-entity-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
    else {
      this.route.navigate(['/aml-screening/risk-corporate-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
  }
  async createAmlInvestigation(type: string) {
    let payload;
    if (type === 'Individual' || type === "Entity") {
      payload = {
        "entityName": this.entityMasterData.fullName[0],
        "countryName": this.countryObject?.countryName || null,
        "entityCategoryList": this.entityMasterData.category.map((e) => e.name),
        "entityGuid": this.entityAmlData.guid,
        "entityType": this.entityMasterData.entityType
      }
    }
    else {
      payload = {
        "entityName": this.entityMasterData.fullName[0],
        "countryName": null,
        "entityCategoryList": this.entityMasterData.category.map((e) => e.name),
        "entityGuid": this.entityAmlData.guid,
        "entityType": this.entityMasterData.entityType
      }
    }

    await this._rs.creatAmlInvestigation(payload).toPromise();
  }

}
