import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "assignRandomColors"
})
@Injectable({
    providedIn: 'root' // Or provide it in your module
  })
export class AssignRandomColors implements PipeTransform {

    shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    assignColors(objects, colors) {
        let availableColors = [...colors]; // Copy of color list
        let colorIndex = 0;
        objects.forEach(obj => {
            if (colorIndex === availableColors.length) {
            availableColors = this.shuffle([...colors]); // Reshuffle once all colors are used
            colorIndex = 0;
            }
            obj.color = availableColors[colorIndex]; // Assign color to object
            colorIndex++;
        });
        return objects;
    }

    async transform(list: []) {
        let res = []
        if(list?.length){
            // Random colors for adverse tags, if need to add more colors or change it, also change to main style.scss
            const colors = ["#C27BA0", "#5097D9", "#5A5D60", "#ED7C15", "#7F8C64", "#AB48B3", "#EF5DA8", "#DD997B", "#23A393", "#B4654C", "#242424", "#3835C9", "#6349FF", "#DCAF0E", "#E05B3B", "#E43535", "#B27AB03D", "#5097D93D", "#5A5D603D", "#C27BA03D"];
            res = this.assignColors(list, colors);
        }
        return res;
    }
}