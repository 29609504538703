import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services';
import { KycModalComponent } from '../../../../shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../../risk-screening.service';
import * as d3 from 'd3';
import { select, geoMercator, geoPath, json, event } from 'd3';

@Component({
  selector: 'app-aml-results-adverse-page-corp-india',
  templateUrl: './aml-results-adverse-page-corp-india.component.html',
  styleUrls: ['./aml-results-adverse-page-corp-india.component.scss', '../../risk-screening.component.scss']
})
export class AmlResultsAdversePageCorpIndiaComponent implements OnInit {
  entityMasterData: any;
  countryObject: any;
  adverseList: any[] = [];
  isOccupationMore: boolean = false;
  public bsModalRef: BsModalRef;
  investigationQuery: any;
  companyType: any;
  queryParams: any;

  constructor(private _rs: RiskScreeningService, private route: Router, private _data : DataService, private modalService: BsModalService,private _aRoute: ActivatedRoute) {
    this._aRoute.queryParams.subscribe(params => {
      this.companyType = params['companyType'];
      this.queryParams = params
  });
  }

  ngOnInit() {
    const amlData = JSON.parse(sessionStorage.getItem('entityAmlData'));
    this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
    if (amlData?.masterData) {
      this.entityMasterData = amlData.masterData;
    }
    if (this.entityMasterData?.nationality) {
      this.getCountryObject(this.entityMasterData.nationality[0]);
    }
    else if (this.entityMasterData?.countryOfCitizenship) {
      this.getCountryObject(this.entityMasterData.countryOfCitizenship[0]);
    }
    if (!this.entityMasterData?.fullName?.length) {
      if(sessionStorage.getItem('amlSearchQuery')){
        const obj = { fullName: [this.investigationQuery?.searchKey] };
        this.entityMasterData = obj;
      }
      else{
        this.route.navigate(['/aml-screening/aml-investigation-results-corp-india/aml'], {
          replaceUrl: true,
          queryParams: this.queryParams
        });
      }
    }
  }

  getCountryObject(country) {
    this._rs.getCountryDetailsByCountry(country).subscribe((res) => this.countryObject = res['data']);
  }

  goToAML(){
    this.route.navigate(['/aml-screening/aml-investigation-results-corp-india/aml'], {
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  goToCorporateCheck(){
    this.route.navigate(['/aml-screening/aml-investigation-results-corp-india/corporate-check'], {
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  getAdverseData(event){
    if(event){
      const adverseData = JSON.parse(event);
      if(!this.entityMasterData?.entityType){
        let entityType = this.investigationQuery?.investigationType;
        if(adverseData?.content){
          let findData;
          for(let item of adverseData?.content){
            if(!findData){
              findData = item?.analysis?.entiries?.find((f) => f?.entity.toLowerCase() == this.investigationQuery?.searchKey.toLowerCase())?.entityType;
              if(findData){
                entityType = findData
              }
            }
          }
        }
        const amlData = {fullName: [this.investigationQuery?.searchKey], entityType: entityType};
        this.entityMasterData = amlData;
      }
    }
  }

  goToReport(){
    let entityType;
    if(this.entityMasterData?.entityType){
      entityType = this.entityMasterData?.entityType;
    }
    else{
      entityType = this.investigationQuery?.investigationType;
    }
    if(!entityType || entityType?.toLowerCase() == 'individual'){
      this.route.navigate(['/aml-screening/risk-individual-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
    else if(entityType?.toLowerCase() == 'entity'){
      this.route.navigate(['/aml-screening/risk-entity-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
    else{
      this.route.navigate(['/aml-screening/risk-corporate-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
  }

}
