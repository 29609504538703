import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { RiskScreeningService } from '../risk-screening.service';

@Component({
  selector: 'app-scheduled-checks',
  templateUrl: './scheduled-checks.component.html',
  styleUrls: ['./scheduled-checks.component.scss', '../risk-screening.component.scss']
})

export class ScheduledChecksComponent implements OnInit {
  page: number = 1;
  size: number = 10;
  tableList: any[] = [];
  totalItems: number = 0;
  totalPage: number = 0;
  itemPerPage: any[] = [
    { value : 10 },
    { value : 25 },
    { value : 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  searchKey: any;
  order: string;
  orderArray: any[] = [
    { key: 'Latest First', value: 'DESC' },
    { key: 'Oldest First', value: 'ASC' },
  ]
  
  constructor(
    private _data: DataService,
    private _rs: RiskScreeningService,
  ) { }

  ngOnInit() {
    this.order = 'DESC';
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  getTableData(page, size, order){
    const payload = {
      "page" : page ,
      "size" : size,
      "order": order,
      "sortBy": "createdDate",
      "entityName": this.searchKey
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getScheduledChecksList(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.tableList = res['content'] || [];
      this.totalItems = res['totalElements'];
      this.totalPage = res?.totalPages;
      this.initialNumberElement = page * size + 1;
        if (this.initialNumberElement + size > this.totalItems) {
          this.secondElement = this.totalItems
        }
        else {
          this.secondElement = this.initialNumberElement + size - 1;
        }
    })
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getTableData(this.page - 1, this.rowPageNo, this.order);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  orderFilter(event){
    this.order = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  clearSearch(){
    this.searchKey = null
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  doSearch(){
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

}

