import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BehaviorSubject } from "rxjs";
import { Role } from "../../models/Role.model";
import { saveAs } from 'file-saver';
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { DevOpsService } from "../../pages/dev-ops/services/dev-ops.service";
import { finalize, first } from "rxjs/operators";
import { Router } from "@angular/router";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { ImageCropperComponent } from "ngx-image-cropper";
import * as RecordRTC from "recordrtc";
import { invokeSaveAsDialog } from "recordrtc";
import { TrackingService } from '../../services/tracking.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StripeCardComponent } from 'ngx-stripe';
import { Stripe } from "@stripe/stripe-js";
import { StripeKey, dataSource } from "../../utils/misc";
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { PaymentService } from "../../services/payment.service";

@Component({
  selector: "app-kyc-modal",
  templateUrl: "./kyc-modal.component.html",
  styleUrls: ["./kyc-modal.component.scss"]
})
export class KycModalComponent implements OnInit {
  //Generic Variables
  title: string;
  type:
    | "create-new-user"
    | "show-image"
    | "create-custom-theme"
    | "do-live-check"
    | "all-user-checks"
    | "confirmation-modal"
    | "pricing-structure"
    | "checkout"
    | "checkout-new"
    | "show-coupons"
    | "override-checks"
    | "set-aml-alert"
    | "schedule-check"
    | "regenerate-keys"
    | "crop-image"
    | "user-logout"
    | "news-detail"
    | "edit-bulk-upload-record"
    | "create-new-batch"
    | "final-submit-confirmation"
    | "test-webhook"
    | "capture-doc"
    | "crop-image-b64"
    | "advanced-aml-search"
    | "add-new-risk-policy"
    | "edit-extracted-data"
    | "advanced-aml-search"
    | "add-new-risk-policy"
    | "test-webhook"
    | "redo-check"
    | "reinvite-check"
    | "status-details"
    | "terms-and-condition"
    | "warning-message"
    | "enterprise-plan"
    | "false-positive-alerts"
    | "DevOps-Override"
    | "card"
    | "update-email"
    | "date-range"
    | "send-email"
    | "comments"
    | "select-type-blacklist"
    | "crr-break-points"
    | "details-modal"
    | "add-individual"
    | "add-bulk"
    | "data-source"
    | "mark-as-false-positive"
    | "data-listing"
    | "add-new-risk-policy-category"
    | "risk-screening-black-list"
    | "risk-screening-add-individual"
    | "risk-screening-add-bulk"
    | "risk-screening-scheduled-checks"
    | "risk-screening-report"
    | "show-aml-info-text"
    | "gloabal-map-aml-listing"
    | "sub-category-data-list"
    | "devops-override-ocr"

  subcategoryData = {
    category: '',
    selectedCategoryList: []
  };
  globalMapAmlData = {
    country: '',
    type: '',
    totalValue: 0,
    listData: []
  };
  todayDate: any;
  viewListData: [];
  viewDatasourceList: boolean = false;
  riskPolicyData: any;
  isunselectAllDatasource: boolean = false;
  addNewRiskPolicyData = {
    countries: [],
    categories: [],
    datasource: [],
    policyName: '',
    description: '',
  }
  riskScreeningReport = {
    // flagedArticle: false,
    entitySummary: false,
    entityDetails: false,
    showAdditionalInfo: false,
    amlScreening: false,
    showRiskScreening: false,
    networkAndRelationShip: false,
    incluseAdverseMedia: false,
    // filterOutMarkFalsePositive: false,
    showCrimeRecord: false,
    showSanctionRecord: false,
    showPepRecord: false,
    showWatchListRecord: false,
    showSipRecord: false,
    showSieRecord: false,
    showSources: false,
    comments: ""
  }
  riskScreeningReportLoopList: Array<object> = [
    // {
    //   id: "switch-1",
    //   name: "Show Flagged articles only",
    //   value: "flagedArticle"
    // },
    {
      id: "switch-1",
      name: "Entity Summary",
      value: "entitySummary"
    },
    {
      id: "switch-2",
      name: "Entity Details",
      value: "entityDetails"
    },
    {
      id: "switch-3",
      name: "Entity Additional Info",
      value: "showAdditionalInfo"
    },
    {
      id: "switch-4",
      name: "Risk Screening",
      value: "showRiskScreening"
    },
    {
      id: "switch-5",
      name: "AML Screening",
      value: "amlScreening"
    },
    // {
    //   id: "switch-6",
    //   name: "Network and Relationship",
    //   value: "networkAndRelationShip"
    // },
    {
      id: "switch-6",
      name: "Include Adverse Media",
      value: "incluseAdverseMedia"
    },
    // {
    //   id: "switch-7",
    //   name: "Filter out marked false positives",
    //   value: "filterOutMarkFalsePositive"
    // },
    {
      id: "switch-7",
      name: "Show PEP Records",
      value: "showPepRecord"
    },
    {
      id: "switch-8",
      name: "Show Crime Records",
      value: "showCrimeRecord"
    },
    {
      id: "switch-9",
      name: "Show Sanction Records",
      value: "showSanctionRecord"
    },
    {
      id: "switch-10",
      name: "Show WatchList Records",
      value: "showWatchListRecord"
    },
    {
      id: "switch-11",
      name: "Show SIP Records",
      value: "showSipRecord"
    },
    {
      id: "switch-12",
      name: "Show SIE Records",
      value: "showSieRecord"
    },
    {
      id: "switch-13",
      name: "Show sources",
      value: "showSources"
    },


  ]

  frequency: any[] = ["DAILY", "WEEKLY", "MONTHLY", "QUARTERLY", "YEARLY", "NOW"];
  categoryList: any[] = [];
  devOpsOverrideData: any;
  docMatch: any;
  idDocCheck2: any;
  adversemediacheck2: any;
  amlCheck2: any;
  emailCheck: any;
  phoneCheck: any;
  userName: string;
  idvWarningMessage: string;
  addressWarningMessage: string;
  selfieWarningMessage: string;
  livenessWarningMessage: string;
  verification = false;
  markAsFalsePositiveData = {};
  size = false;
  source: any;

  addressdoccheck: any;
  adversemediacheck: any;
  corporatecheck: any;
  crimecheck: any;
  iddoccheck: any;
  livenesscheck: any;
  pepcheck: any;
  sanctionscheck: any;
  selfiecheck: any;
  corporateUserInfo: any;
  emailOtpCheck: any;
  smsOtpCheck: any;
  bankCheck: any;
  upiCheck: any;
  nidPan2: any;
  name;
  number;
  cvc;
  expiry;

  overrideDocForm: FormGroup = this.formbuilder.group({
    fname: [''],
    lname: [''],
    fullName: [''],
    sn: [''],
    birthYear: [''],
    dob: [''],
    issue: [''],
    expary: [''],
    docname: [''],
    address: [''],
    issueYear: [''],
    expiryYear: [''],
    score: [''],
    status: ['SUCCESS']
  });
  individualFrom: FormGroup = this.formbuilder.group({
    name: [''],
    fname: [''],
    lname: [''],
    sn: [''],
    dob: [''],
    birthYear: [''],
    issue: [''],
    expary: [''],
    docname: [''],
    address: [''],
    issueYear: [''],
    expiryYear: [''],
  });
  consumptionForm: FormGroup = this.formbuilder.group({
    startCreatedDate: [''],
    endCreatedDate: [''],
    size: [''],
  });
  scheduleCheckForm: FormGroup = this.formbuilder.group({
    startDate: ['', [Validators.required]],
    category: ['', [Validators.required]],
    interval: ['', [Validators.required]]
  });
  blacklistForm: FormGroup = this.formbuilder.group({
    name: ['', [Validators.required]],
    dob: ['', [Validators.required]],
    countryCode: ['', [Validators.required]],
    address: ['', [Validators.required]],
    source: ['', [Validators.required]]
  });
  paymentForm: FormGroup;
  withoutMaterialForm: FormGroup;
  stripeCardValid: boolean = false;
  generateLink:boolean = false;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  stripeKey: any = StripeKey;
  getEmailValue: any;
  extAllowed: any[] = ["text/csv", "application/vnd.ms-excel"];
  //file uploaded
  uploadedFile: any;
  isFileLoaded: boolean = false;
  sourceNameList = dataSource
  filterDataSourceList: any[] = [];

  public onClose = new BehaviorSubject<any>({});

  //vars for roles i.e "create-new-user"
  isUpdate: boolean;
  user: Role;

  //variables for "show-image"
  image: string;

  //variable for "confirmation-modal"
  heroText: string;

  //vars for live check
  userType: any;
  userInfo: any;

  //vars for "all-user-checks"
  email: any;

  //vars for "regenerate-keys"
  account: {
    email: string;
    password: string;
  } = {
      email: "",
      password: ""
    };

  //vars for plan (planStatus: any;)
  planData: any;
  planStatus: any;
  planDuration: any;
  priceCurrency: any;

  //vars for overridden checks
  overriddenChecks: any;

  //vars for set-aml-alert || schedule-check
  endUserInfo: any;

  // vars for news data
  newsData: any = null;

  //vars data for bulkupload-record-edit
  recordTobeEdited: any;

  //vars for final submission of bulk upload
  totalCredit: any;

  //vars for web hook testing functonality
  webHookResponse: null;
  webhook_endpoint: any;
  webhook_sampleData: any;

  //vars for crop image
  imageChangedEvent: any;
  croppedImage: any;
  loadedImageEvent: any;
  showCropper: boolean = false;
  imageFile: any = null;
  canvasRotation = 0;
  rotation = 0;
  transform: ImageTransform = {};

  //capture video
  showVideos: any = {
    showLiveWebCam: false,
    showRecordedWebCam: false
  };
  _selfie: any = Object.create({});
  videoRecorder: any;
  imgAsb64: any;

  extractedData: any;
  typeOfReq: any;
  allCaps: any = /([A-Z])/g;

  localStream: any;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    idField: "id",
    textField: "key",
    allowSearchFilter: true,
    singleSelection: false
  };
  riskName: any;
  isTermsAndCondition: boolean = false;

  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  //var for countries
  countries: any;
  redoCheckMessage: any;
  reprocessCheckMessage: any;
  deviceInfo: any;
  ip: any;
  enterprisePlanMessage: any;
  endUserBookmarkedChangeAlertsData: any;
  generatedRemoteLink: any;
  clientsFlow: any;

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private _app: AppService,
    private data: DataService,
    private devops: DevOpsService,
    private _payment: PaymentService,
    private _track: TrackingService,
    private _router: Router,
    private formbuilder: FormBuilder
  ) {
    const today = new Date();
    this.todayDate = today.toISOString().split('T')[0];
    this.data.clientsConfigs.subscribe(res => {
      this.clientsFlow = res?.['clientsFlow'];
      console.log(res, "clientsFlow")
    })
  }

  ngOnInit() {

    if (this.riskPolicyData) this.filterDataSourceList = this.riskPolicyData.datasourceList
    if (this.type == "advanced-aml-search" || this.type == "select-type-blacklist" || this.type == 'risk-screening-black-list') {
      this.getAllCountries();
    }

    if (this.type == "crop-image") {
      console.log("crop image", this.loadedImageEvent);
      var image: any = new Image();
      // image.onload = function() {
      //     var width = image.naturalWidth ;
      //     var height = image.naturalHeight;
      //     window.URL.revokeObjectURL( image.src );
      //     console.log("width" + width);
      //     console.log("height" + height);
      // };
      var file: File = this.loadedImageEvent[0]
        ? this.loadedImageEvent[0]
        : this.loadedImageEvent.target.files[0];
      // this.imageFile = this.loadedImageEvent[0]
      //   ? this.loadedImageEvent[0]
      //   : this.loadedImageEvent.target.files[0];
      // this.imageFile=this.loadedImageEvent;
      // console.log("image file crop image: ",this.imageFile);
      var name = file.name;
      if (file) {
        var reader = new FileReader();
        var that = this;
        reader.readAsDataURL(file);
        var base64img;
        reader.onloadend = () => {
          base64img = reader.result;
          this.imageFile = reader.result;
        };
      }
    }
    if (this.type == "crop-image-b64") {
      this.imageFile = this.imgAsb64;
    }

    this.paymentForm = this.formbuilder.group({
      email: ['', [Validators.required]]
    });

  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onNewUserCreate($event) {
    //new role
    console.log($event);
    this.closeModal($event);
  }

  onUpdateUser($event) {
    console.log($event);
    this.closeModal($event);
  }

  onPaymentCompletion($event) {
    console.log($event);
    this.closeModal($event);
  }

  saveScheduleChecks() {
    this.bsModalRef.hide();
    this.onClose.next(this.endUserInfo)
  }

  saveRiskScreeningReport() {
    this.bsModalRef.hide();
    this.onClose.next(this.riskScreeningReport)
  }

  saveThefalsePositive() {
    this.bsModalRef.hide();
    const data = {
      comment: this.endUserBookmarkedChangeAlertsData
    }
    this.onClose.next(data);
  }

  closeModal(data?: any) {
    var that = this;
    if (this.videoRecorder) {
      this.videoRecorder.stopRecording(function (url) {
        console.log(url);
        that._selfie.videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
      });
    }
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  clickClose() {
    this.bsModalRef.hide();
    if (this.type == "checkout") {
      window.location.reload();
    } else if (this.type == "capture-doc") {
      this.closeModal(null);
    } else if (this.type == "checkout-new") {
      this.onClose.next({ closed: true });
      const currentUrl = this._router.url;
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this._router.navigate([currentUrl]);
      });
    } else {
      this.onClose.next({});
    }
  }
  updateEmail() {
    this.bsModalRef.hide();
    this.onClose.next(this.getEmailValue)
  }
  copyLinkToClipboard(){
    const copyContent = async () => {
      try {
        await navigator.clipboard.writeText(this.generatedRemoteLink);
        this.data.toastrShow(`Remote KYC Link copied`, 'success');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
    copyContent()
    
  }
  sendRemoteLink() {
    let showRedoBtn = true;
    let query = {
      username: this.userName,
      message: this.redoCheckMessage,
      sendEmailForRemoteLink:!this.generateLink
      
    }
    this.data.changeLoaderVisibility(true);
    this._app.sendRemoteLinkForRedo(query).subscribe(async res => {
      console.log(999,res)
      showRedoBtn = false;
      this.data.changeLoaderVisibility(false);
    
      if(this.generateLink){
        this.generatedRemoteLink = res?.data?.remoteKycUrl
        this.data.toastrShow(res.message, "info")
      }
      else{
        this.data.toastrShow(res.message, "info")
        this.closeModal(showRedoBtn);
        await this._track.activityLog();
      }
   
    },
      err => {
        this.data.changeLoaderVisibility(false);
      });
  }
  sendPlanRequest() {

    let query = {

      message: this.enterprisePlanMessage
    }
    this.data.changeLoaderVisibility(true);
    this._app.enterprisePlan(query).subscribe(res => {

      // this.data.changeLoaderVisibility(false);
      this.data.toastrShow(res.message, "info")
      this.data.changeLoaderVisibility(false);
      this.closeModal();

    },
      err => {
        this.data.changeLoaderVisibility(false);
      });
  }

  sendReprocessLink() {
    // let showRedoBtn = true;
    let query = {
      username: this.userName,
      message: this.reprocessCheckMessage
    }
    this.data.changeLoaderVisibility(true);
    this._app.resendRemoteLink(query).subscribe(async res => {
      // showRedoBtn = false;
      this.data.changeLoaderVisibility(false);
      this.data.toastrShow(res.message, "info")
      this.closeModal();
      await this._track.activityLog();
    },
      err => {
        this.data.changeLoaderVisibility(false);
      });
  }


  onNewKycUserCreate($event) {
    console.log(993,$event);
    if ($event) {
      let content = {
        endUser: $event.data.checks.endUser,
        corporateUserInfo: null,
        individualUserInfo: $event.data.individualUserInfo
      };
      if ($event.data["pendingChecks"]) {
        content["pendingChecks"] = $event.data["pendingChecks"];
      }
      if ($event.data["totalChecks"]) {
        content["totalChecks"] = $event.data["totalChecks"];
      }
      this.closeModal(content);
    } else {
      this.closeModal(null);
    }
  }

  onCouponRedemption($event) {
    if ($event) {
      console.log($event);
      this.closeModal($event);
    } else {
      this.closeModal(null);
    }
  }

  onOverrideChecks($event) {
    if ($event) {
      console.log($event);
      this.closeModal($event);
    } else {
      this.closeModal(null);
    }
  }

  onGettingId($event) {
    this.closeModal($event);
  }

  async sendRequest(sendNow: boolean) {
    await this._track.activityLog();
    let obj = {
      sendNow: sendNow
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  doLogout(shouldLogout: boolean) {
    let obj = {
      shouldLogout: shouldLogout
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  doFinalSubmit(shouldPay: boolean) {
    let obj = {
      shouldPay: shouldPay
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  reGenerateKeys() {
    this.closeModal(this.account);
  }

  getAdverseMediaNewImage(data) {
    if (
      data.pagemap &&
      data.pagemap.cse_image &&
      data.pagemap.cse_image.length &&
      data.pagemap.cse_image[0] &&
      data.pagemap.cse_image[0]
    ) {
      return data.pagemap.cse_image[0].src;
    } else if (
      data.pagemap &&
      data.pagemap.cse_thumbnail &&
      data.pagemap.cse_thumbnail.length &&
      data.pagemap.cse_thumbnail[0] &&
      data.pagemap.cse_thumbnail[0]
    ) {
      return data.pagemap.cse_thumbnail[0].src;
    } else {
      return "assets/images/profile-picture.png";
    }
  }

  getAllCountries() {
    this._app.getAllCountries().subscribe(res => {
      this.countries = res["data"];
      console.log(res);
    });
  }

  //functions for crop image

  cropImage() {
    let data = {
      image: this.croppedImage
    };
    this.closeModal(data);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log("Image loaded", this.showCropper);
  }
  cropperReady() {
    console.log("Cropper ready");
  }
  loadImageFailed() {
    console.log("Load failed");
  }
  rotateLeft() {
    // this.imageCropper.rotateLeft();
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    // this.imageCropper.rotateRight();
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    // this.imageCropper.flipHorizontal();
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    // this.imageCropper.flipVertical();
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  //batch creation function
  onNewBatchCreate($event) {
    this.closeModal($event);
  }

  //update a single record for bulk-upload
  onEditRecord($event) {
    this.closeModal($event);
  }
  // schdule check function
  onNewScheduleEvent($event) {
    this.closeModal($event);
  }

  //function for testing webhook api
  //test webhook
  testWebHook() {
    this._app.testWebHoook().subscribe(
      res => {
        this.webHookResponse = res;
        console.log("WEBHOOK TESTING: ", res);
      },
      err => {
        console.log(err);
      }
    );
  }
  checkForPermission() {
    var that = this;
    navigator.mediaDevices
      .getUserMedia({
        video: true
      })
      .then(
        successCallback => {
          console.log("runn callback", successCallback);
          this.showVideos.showLiveWebCam = true;
          this.showVideos.showRecordedWebCam = false;
          setTimeout(() => {
            var video = <HTMLMediaElement>(
              document.getElementById("liveWebcamVideo")
            );
            video.srcObject = successCallback;
            video.play();
            that.localStream = video;
            this._selfie.videoStream = successCallback;
            this.startVideoRecording();
          }, 0);
        },
        errorCallback => {
          let alertObj = {
            message: "Please Allow Camera",
            status: "danger",
            autoDismiss: true,
            timeToDismiss: 10000
          };
          console.log(alertObj);
          // this._data.displayUniversalAlert(alertObj);
          // this._data.toastrShow("Please Allow Camera", "info");
        }
      );
  }

  startVideoRecording() {
    this.videoRecorder = RecordRTC(this._selfie.videoStream, {
      type: "video",
      frameInterval: 45,
      frameRate: 30
    });
    this.videoRecorder.startRecording();
  }

  triggerSnapshot() {
    var canvas = <HTMLCanvasElement>document.getElementById("snapCanvas");
    var context = canvas.getContext("2d");
    var video = <any>document.getElementById("liveWebcamVideo");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // context.drawImage(video, 0, 0, 640, 480, 0, 0, 640, 480);

    var data = canvas.toDataURL("image/png");
    console.log(data);
    if (this.type === "capture-doc") {
      this.closeModal(data);
    }
    // console.log(data);
  }
  saveRiskPolicy() {
    this.closeModal([
      {
        riskName: this.riskName,
        selectedItems: this.selectedItems
      }
    ]);
  }
  overrideDocVerify() {
    this.closeModal(this.overrideDocForm.value)
  }

  editOverride(editType) {
    if (editType === 'name') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "name",
        "value": this.individualFrom.value.name != "" ? this.individualFrom.value.name : null
      }
    } else if (editType === 'fname') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "firstName",
        "value": this.individualFrom.value.fname != "" ? this.individualFrom.value.fname : null
      }
    } else if (editType === 'lname') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "lastName",
        "value": this.individualFrom.value.lname != "" ? this.individualFrom.value.lname : null
      }
    } else if (editType === 'sn') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "serialNo",
        "value": this.individualFrom.value.sn != "" ? this.individualFrom.value.sn : null
      }
    } else if( editType === 'birthYear'){
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "birthYear",
        "value": this.individualFrom.value.birthYear != "" ? this.individualFrom.value.birthYear : null
      }
    } 
    else if (editType === 'dob') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "dob",
        "value": this.individualFrom.value.dob != "" ? this.individualFrom.value.dob : null
      }
    } else if (editType === 'issue') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "issueDate",
        "value": this.individualFrom.value.issue != "" ? this.individualFrom.value.issue : null
      }
    } else if (editType === 'address') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "address",
        "value": this.individualFrom.value.address != "" ? this.individualFrom.value.address : null
      }
    } else if (editType === 'expary') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "expiryDate",
        "value": this.individualFrom.value.expary != "" ? this.individualFrom.value.expary : null
      }
    }
    this.data.changeLoaderVisibility(true);
    this.devops.editIndividualUserArea(this.devOpsOverrideData.clientId, payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe(() => {
      let popup = {
        message: editType + " " + "updated successfully",
        status: 'success'
      }
      this.data.toastrShow(popup.message, popup.status);
    })
  }

  okOverride(editType) {
    if (editType === 'name') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "name",
        "status": "PASS"
      }
    } else if (editType === 'fname') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "firstName",
        "status": "PASS"
      }
    } else if (editType === 'lname') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "lastName",
        "status": "PASS"
      }
    } else if (editType === 'sn') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "sn",
        "status": "PASS"
      }
    } else if(editType === 'birthYear'){
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "birthYear",
        "status": "PASS"
      }
    } else if (editType === 'dob') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "dob",
        "status": "PASS"
      }
    } else if (editType === 'issue') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "issueDate",
        "status": "PASS"
      }
    } else if (editType === 'address') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "address",
        "status": "PASS"
      }
    } else if (editType === 'expary') {
      var payload = {
        "checkGuid": this.devOpsOverrideData.checkGuid,
        "key": "expiryDate",
        "status": "PASS"
      }
    }
    this.data.changeLoaderVisibility(true);
    this.devops.overrideIndividualStatusUserArea(this.devOpsOverrideData.clientId, payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe(() => {
      let popup = {
        message: editType + " " + "verified successfully",
        status: 'success'
      }
      this.data.toastrShow(popup.message, popup.status);
    })
  }

  toggle() {
    this.verification = !this.verification;
  }

  get validForm() {
    return this.paymentForm.valid && this.stripeCardValid;
  }


  onChange($event) {
    this.stripeCardValid = $event.complete;
    console.log(this.stripeCardValid)
  }
  saveCustomer() {
    // let payload={'email':this.paymentForm.value.email}
    // this._payment.createStripeCustomer(payload).subscribe(res=>{
    console.log(this.card.getCard())
    // })
  }

  getRecords() {
    this.closeModal(this.consumptionForm.value)
  }

  sendEmail(value) {
    if (value == 'email') {
      this.bsModalRef.hide();
      this.onClose.next(this.getEmailValue)
    }
    else {
      this.bsModalRef.hide();
      this.onClose.next('default')
    }

  }

  screeningScheduleChecks() {
    this.bsModalRef.hide();
    this.onClose.next(this.scheduleCheckForm.value)
  }

  searchDatasourceList(event) {
    if (event) {
      this.filterDataSourceList = this.riskPolicyData.datasourceList.filter((f) => f.dataSourceName.toUpperCase().includes(event.toUpperCase()))
    }
    else {
      this.filterDataSourceList = this.riskPolicyData.datasourceList
    }
  }

  selectAllDatasource() {
    if (!this.isunselectAllDatasource) {
      this.riskPolicyData.datasourceList.forEach((e) => e.isTick = true);
      this.isunselectAllDatasource = true;
    }
    else {
      this.riskPolicyData.datasourceList.forEach((e) => e.isTick = false);
      this.isunselectAllDatasource = false;
    }
    this.filterDataSourceList = this.riskPolicyData.datasourceList
  }

  clearAll() {
    this.addNewRiskPolicyData = {
      countries: [],
      categories: [],
      datasource: [],
      policyName: '',
      description: '',
    }
  }

  saveRiskPolicyData() {
    this.addNewRiskPolicyData.datasource = [];
    this.riskPolicyData.datasourceList.forEach((e) => {
      if (e.isTick) this.addNewRiskPolicyData.datasource.push(e.dataSourceName)
    });
    const countriesObj = this.addNewRiskPolicyData.countries.map(country => {
      if (country.countryName) {
        return country
      }
      else {
        return { countryName: country };
      }
    });
    const categoriesObj = this.addNewRiskPolicyData.categories.map(category => {
      if (category.categoryName) {
        return category
      }
      else {
        return { categoryName: category };
      }
    });
    this.addNewRiskPolicyData.countries = countriesObj;
    this.addNewRiskPolicyData.categories = categoriesObj;
    this.bsModalRef.hide();
    this.onClose.next(this.addNewRiskPolicyData)
  }

  saveEntity() {
    this._app.saveBlacklist(this.blacklistForm.value).subscribe(res => {
      if (res.blackListEntitiesId) {
        this.closeModal('success');
      }
    })
  }
  fileChangeListener($event) {
    // this.uploadedFile = $event[0] ? $event[0] : $event.target.files[0];
    let uploadedFile = $event[0] ? $event[0] : $event.target.files[0];
    if (uploadedFile && this.extAllowed.indexOf(uploadedFile.type) >= 0) {
      var reader = new FileReader();
      this.uploadedFile = uploadedFile;
      this.isFileLoaded = true;
    }
    else {
      // this.isFileLoaded = false;
      this.data.toastrShow("File extension not supported , we support only csv.", "error");
    }
  }

  upload() {
    this.data.changeLoaderVisibility(true);
    let formData = new FormData();
    formData.append("file", this.uploadedFile);
    formData.append("source", this.source)
    this._app.uploadBlackListFile(formData).subscribe(res => {
      this.data.changeLoaderVisibility(false);
      this.closeModal('success');
    },
      err => {
        this.data.changeLoaderVisibility(false);
      }
    )
  }

  downloadCSV() {
    // this.data.changeLoaderVisibility(true);
    this._app.getCSVfile().subscribe(res => {
      const data: Blob = new Blob([res], {
        type: "text/csv;charset=utf-8"
      });
      saveAs(data, "sample.csv");
    })
  }
}
