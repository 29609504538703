import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../../services';
import { RiskScreeningService } from '../../risk-screening.service';

@Component({
  selector: 'app-aml-results-table-corp-india',
  templateUrl: './aml-results-table-corp-india.component.html',
  styleUrls: ['./aml-results-table-corp-india.component.scss',  '../../risk-screening.component.scss']
})
export class AmlResultsTableCorpIndiaComponent implements OnChanges {
  @Input() amlData;
  newPage;
  page: number = 1;
  size: number = 10;
  order: string;
  orderArray: any[] = [
    { key: 'Latest First', value: 'DESC' },
    { key: 'Oldest First', value: 'ASC' },
  ]
  tableList: any[] = [];
  totalItems: number = 0;
  totalPage: number = 0;
  itemPerPage: any[] = [
    { value: 10 },
    { value: 25 },
    { value: 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  investigationQuery: any = {
    searchKey: null,
    investigationType: 'All',
    riskPolicy: 'ALL',
    country: null,
    searchType: "FUZZY"
  };
  resultsCount: number = 0;
  isHideFilters: boolean = true;
  keywords: any[] = [];
  filterArray: any[] = [];
  filterKeyArray: any[] = [];
  fromParent: boolean = false;
  public inFetchLocalConfig: () => void = () => { }
  searchFilter: boolean = false;
  companyType: any;
  queryParams: any;


  constructor(private _data: DataService, private _rs: RiskScreeningService, private route: Router, private _aRoute: ActivatedRoute) {
    this._aRoute.queryParams.subscribe(params => {
      this.companyType = params['companyType'];
      this.queryParams = params
  });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.order = 'DESC';
    // if(changes.amlData && changes.amlData.currentValue){
    //   this.amlData =  changes.amlData.currentValue
    //   this.tableList = this.amlData['content'] || [];
    //   this.totalItems = this.amlData['totalElements'];
    //   console.log(this.tableList,'onchange')
    // }
    if (sessionStorage.getItem('topSearch')) {
      this.investigationQuery = JSON.parse(sessionStorage.getItem('topSearch'));
      // this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, true);
    }
    else if (sessionStorage.getItem('amlSearchResponse')) {
      const response = JSON.parse(sessionStorage.getItem('amlSearchResponse'));
      this.tableList = response['content'] || [];
      this.totalItems = response['totalElements'];
      this.totalPage = response?.totalPages;
      this.resultsCount = response['totalElements'];
      this.initialNumberElement = (this.page - 1) * this.rowPageNo + 1;
      if (this.initialNumberElement + this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.initialNumberElement + this.rowPageNo - 1;
      }
      this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
      this.getFilterData(this.page - 1, this.rowPageNo, this.order);
    }
    else {
      if (sessionStorage.getItem('amlSearchQuery')) {
        this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
        // this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, true);
      }
    }
  }

  public customFunctionForSearchFilter(fromParent: boolean, investigationQuery: any, inFetchLocalConfig) {
    this.fromParent = fromParent;
    this.investigationQuery = investigationQuery;
    this.inFetchLocalConfig = inFetchLocalConfig;
    this.rowPageNo = 10;
    this.searchFilter = true;
    this.pageChange(1)
  }

  getTableData(page, size, order, filterKeyArray, searchFilterCalled?: boolean) {
    console.log(this.investigationQuery, 'child')
    const payload = {
      "size": size,
      "page": page,
      "searchType": this.investigationQuery.searchType,
      "name": [this.investigationQuery.searchKey],
      "country": this.investigationQuery.country ? this.investigationQuery.country : [],
      "source": this.investigationQuery['dataSources'] ? this.investigationQuery['dataSources'] : [],
      "guid": "",
      "category": this.investigationQuery['categories'] ? this.investigationQuery['categories'] : [],
      "gender": [],
      "type": this.investigationQuery.investigationType ? [this.investigationQuery.investigationType] : []
    };
    for (let item of filterKeyArray) {
      payload[item.key] = item.value;
    }
    this._data.changeLoaderVisibility(true);
    this._rs.doAmlSearch(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if (this.fromParent) {
        this.inFetchLocalConfig()
      }
      if (res) {
        if (this.fromParent) {
          sessionStorage.removeItem('amlSearchQuery');
          sessionStorage.setItem('amlSearchQuery', JSON.stringify(this.investigationQuery));
        }
      }
      this.tableList = res['content'] || [];
      this.totalItems = res['totalElements'];
      if (searchFilterCalled || this.searchFilter) {
        this.getFilterData(page, size, order);
        this.resultsCount = res['data']?.totalElements;
      }
      this.initialNumberElement = page * size + 1;
      if (this.initialNumberElement + size > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.initialNumberElement + size - 1;
      }
    })
  }

  getFilterData(page, size, order) {
    const payload = {
      "size": size,
      "page": page,
      "searchType": this.investigationQuery.searchType,
      "name": [this.investigationQuery.searchKey],
      "country": this.investigationQuery.country ? this.investigationQuery.country : [],
      "source": this.investigationQuery['dataSources'] ? this.investigationQuery['dataSources'] : [],
      "guid": "",
      "category": this.investigationQuery['categories'] ? this.investigationQuery['categories'] : [],
      "gender": [],
      "type": this.investigationQuery.investigationType ? [this.investigationQuery.investigationType] : []
    }
    this._data.changeLoaderVisibility(true);
    this._rs.getSearchFilters(payload).pipe(finalize(() => { this._data.changeLoaderVisibility(false); this.searchFilter = false; })).subscribe((res) => {
      if (res['data']) {
        this.keywords = [];
        this.filterArray = [];
        for (let key of Object.keys(res['data'])) {
          let keywordsObj = { heading: key, value: false, filters: [] }
          for (let element of res['data'][key]) {
            if (element.key != '') {
              let filterObj = { key: element.key, value: false, keyName: "", doc_count: 0 };
              if (element.key.includes('//')) {
                const splitKey = element.key.split('//');
                filterObj['keyName'] = splitKey[1];
              }
              else {
                filterObj['keyName'] = element.key;
              }
              filterObj['doc_count'] = element.doc_count;
              keywordsObj['filters'].push(filterObj)
            }
          }
          this.keywords.push(keywordsObj);
        }
      }
    });
  }

  // Expand Filter

  getIndexValueOfApplyFilters(i) {
    if (this.filterArray.indexOf(i) > -1) {
      this.filterArray.splice(this.filterArray.indexOf(i), 1);
    } else {
      this.filterArray.push(i);
    }
  }

  applyAllFilter(index) {
    this.filterKeyArray = [];
    for (var i = 0; this.keywords.length > i; i++) {
      let obj = { key: '', value: [] }
      obj['key'] = this.keywords[i].heading;
      if (i == index) {
        this.keywords[i].filters.map((e) => e.value = this.keywords[i].value);
      }
      this.keywords[i].filters.forEach((item) => {
        if (item.value) {
          obj['value'].push(item.key);
        }
      });
      this.filterKeyArray.push(obj);
    }
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  applyFilter() {
    this.filterKeyArray = [];
    for (let e of this.keywords) {
      let obj = { key: '', value: [] }
      obj['key'] = e.heading;
      e.filters.forEach((item) => {
        if (item.value) {
          obj['value'].push(item.key);
        }
      });
      this.filterKeyArray.push(obj);
    }
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  clearAllFilter() {
    this.filterKeyArray = [];
    this.keywords.forEach((e) => { e.value = false; e.filter = e.filters.map((m) => m.value = false) });
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  orderFilter(event) {
    this.order = event;
    this.page = 1;
    // this.getTableData(this.page - 1, this.rowPageNo, this.order);
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  goToAML(item) {
    sessionStorage.removeItem('entityAmlData');
    if (item) {
      sessionStorage.setItem('entityAmlData', JSON.stringify(item));
      this.route.navigate(['/aml-screening/aml-investigation-results-corp-india/aml'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
  }

  goToAdversePage() {
    this.route.navigate(['/aml-screening/aml-investigation-results-corp-india/adverse'], {
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  goToReport() {
    let entityType = JSON.parse(sessionStorage.getItem('amlSearchQuery')).investigationType;
    if (!entityType || entityType == 'Individual') {
      this.route.navigate(['/aml-screening/risk-individual-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
    else if (entityType == 'Entity') {
      this.route.navigate(['/aml-screening/risk-entity-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
    else {
      this.route.navigate(['/aml-screening/risk-corporate-report-corp-india'], {
        replaceUrl: true,
        queryParams: this.queryParams
      });
    }
  }

}
