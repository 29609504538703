import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize, first } from "rxjs/operators";
import { AppService, DataService } from '../../../../../services';
import { KycModalComponent } from '../../../../../../app/shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../../../risk-screening.service';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-new-investigation',
  templateUrl: './new-investigation.component.html',
  styleUrls: ['./new-investigation.component.scss', '../../../risk-screening.component.scss']
})
export class NewInvestigationComponent implements OnInit {
  showTextValidation:boolean = false;
  showIdValidation:boolean = false;
  newInvestigationTypesArr = [
    { key: 'Individual', value: 'Individual' },
    { key: 'Organisation', value: 'Organisation' },
    { key: 'Vessel', value: 'Vessel' },
    { key: 'Aircraft', value: 'Aircraft' }, 
    { key: 'Crypto', value: 'Crypto' }, 
    { key: 'All', value: '' },
  ];
  currencyType: any[] = [
    { key: "BTC - Bitcoin" },
    { key: "BCH - Bitcoin Cash" },
    { key: "BSC - Binance Smart Chain" },
    { key: "ETH - Ethereum" },
    { key: "ETC - Ethereum Classic" },
    { key: "LTC - Litecoin" },
    { key: "TRX - Tron" }
  ]
  showInfo: boolean = false;
  countries: any = [];
  riskPolicyData: any[] = [];
  searchTypeOptions = [
    { key: 'Near Match', value: 'FUZZY' },
    { key: 'Broad Match', value: 'MOREFUZZY' },
    { key: 'Strict Match', value: 'STRICT' },
  ];
  investigationQuery: any = {
    searchKey: null,
    investigationType: 'Individual',
    riskPolicy: null,
    country: null,
    searchType: "FUZZY",
    isRegistryCheck: false,
    companyId: null,
    companyType:null,
    threshold:null,
    uploadedFiles:null,
    btcAddress: null
  };
  allValidationKeys = ['searchKey'];
  failedValidationKeys = [];

  companyType =  [
    { key: 'MCA Registered Entity', value: 'MCA Registered Entity' },
    { key: 'NGOs', value: 'NGOs'},
  ];

  threshold = [
    { key: '10% and above', value: '10'},
    { key: '15% and above', value: '15'},
    { key: '25% and above', value: '25'},
  ]

  extAllowed: any[] = ["image/png", "image/jpe", "image/jpge"];

  // same route navigation vars
  navigationSubscription: any;
  clearDisabled: boolean = true;
  public bsModalRef: BsModalRef;
  uploadedFile: any=null;
  isFileLoaded: boolean;
  apiConstants={
    INITIATED:'INITIATED',
    INPROGRESS:'INPROGRESS',
    SUCCESS:'SUCCESS',
    FAILED:'FAILED'
  }
  MCAApiStatus= this.apiConstants.INITIATED;

  @ViewChild('attachments') attachment: any;
  fileList: any[]=[];
  httpBE: any;
  MCAData: any;

  constructor(
    private _app: AppService,
    public router: Router,
    private _rs: RiskScreeningService,
    private _data: DataService,
    private modalService: BsModalService,
    handler: HttpBackend
  ) {
    this.httpBE = new HttpClient(handler)
  }

  ngOnInit() {
    this.getAllCountries();
    this.getAllDataSource();
    if(sessionStorage.getItem('amlSearchQuery')){
      this.investigationQuery = JSON.parse(sessionStorage.getItem('amlSearchQuery'));
      this.investigationQuery.uploadedFiles = null;
      if(this.investigationQuery?.isRegistryCheck){
        this.newInvestigationTypesArr = this.newInvestigationTypesArr.filter((f) => f.key == 'Organisation');
        this.investigationQuery.investigationType = 'Organisation';
      }
      this.clearDisabled = false;
    }
  }

  registryCheck(){
    this.investigationQuery = { ...this.investigationQuery, investigationType: 'Individual', companyId: null };
    if(this.investigationQuery?.isRegistryCheck){
      this.newInvestigationTypesArr = this.newInvestigationTypesArr.filter((f) => f.key == 'Organisation');
      this.investigationQuery.investigationType = 'Organisation';
    }
    else{
      this.newInvestigationTypesArr = [
        { key: 'Individual', value: 'Individual' },
        { key: 'Organisation', value: 'Organisation' },
        { key: 'Vessel', value: 'Vessel' },
        { key: 'Aircraft', value: 'Aircraft' },
        { key: 'Crypto', value: 'Crypto' }, 
        { key: 'All', value: '' },
      ];
      this.investigationQuery.investigationType = 'Individual';
    }
  }

  getAllDataSource(){
    this._rs.getRiskPolicyData().subscribe((res) =>{
      this.riskPolicyData = res || [];
    })
  }

  onValueChange(event, type?) {
    if (event) {
      this.clearDisabled = false;
    }
    if(type == 'country'){
      this.investigationQuery.country = [this.investigationQuery.country]
    }
  }

  clearAll() {
    this.clearDisabled = true;
    this.investigationQuery = {
      searchKey: null,
      investigationType: this.investigationQuery?.isRegistryCheck ? 'Organisation' : 'Individual',
      riskPolicy: null,
      country: null,
      searchType: "FUZZY",
      isRegistryCheck: this.investigationQuery?.isRegistryCheck,
      companyId: null,
      btcAddress: null
    };
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
        },
        err => {
          console.log(err);
        }
      );
  }

  fetchBeneficialOwnersData(){
    const headers = new HttpHeaders()
    .set('Authorization', "!@#$VGYJK*(&^ftr546JK$6")
    .set('Cookie', "PHPSESSID=9lpkb3c8c42ahb1marq48gokf1; __obsgeo=NA%7CGB%7C%26pound%3B")
    .set('accept', "application/json");
    const formData = new FormData();
    // Append each file to FormData object
    for (let i = 0; i < this.fileList.length; i++) {
      formData.append('files', this.fileList[i]);
    }
    this.MCAApiStatus = this.apiConstants.INPROGRESS;
    const dataObj = {
      uploadedFiles: this.fileList,
      response:null,
      apiStatus: this.MCAApiStatus,
      searchKey: this.investigationQuery?.searchKey
    }
    this._data.setMCAData(JSON.stringify(dataObj));
    this.httpBE.post(`https://dt.kychub.com/table/v1/transform/`, formData,{ headers }).subscribe(
        (response) => {
          console.log(response);
          this.MCAApiStatus = this.apiConstants.SUCCESS;
          this.MCAData = response?.['extracted_data'];
          this._data.toastrShow("MCA Data extracted successfully", "success");
          dataObj.response = response;
          dataObj.apiStatus = this.MCAApiStatus;
          this._data.setMCAData(JSON.stringify(dataObj));
        },
        (error) => {
          this.MCAApiStatus = this.apiConstants.FAILED;
          console.error(error);
          this._data.toastrShow("MCA Data extraction failed", "error");
          dataObj.apiStatus = this.MCAApiStatus;
          this._data.setMCAData(JSON.stringify(dataObj));
        }
      );
    }

  checkFieldValidation(field){
    if(this.allValidationKeys.includes(field)){
      if(this.investigationQuery?.[field]){
        this.failedValidationKeys = this.failedValidationKeys.filter(e => e !== field)
      }else{
        this.failedValidationKeys.push(field);
      }
    }
  }

  includeInAllValidationKeys(value){
    if(!this.allValidationKeys.includes(value)){
      this.allValidationKeys.push(value);
    }
  }

  addConditionalValidations(){
    if(this.investigationQuery?.investigationType === 'Organisation' && this.investigationQuery?.country?.[0] === 'India' && this.investigationQuery?.isRegistryCheck){
      this.includeInAllValidationKeys("companyType")
      if(this.investigationQuery?.companyType === 'MCA Registered Entity'){
        // this.allValidationKeys.push('uploadedFiles');
        this.includeInAllValidationKeys("companyId")
      }else{
        this.allValidationKeys = this.allValidationKeys.filter(e => e !== 'companyId');
        this.doFailedValidation();
        this.checkFieldValidation("companyId");
      }
    }
  }

  doFailedValidation(){
    this.failedValidationKeys = [];
    if(this.allValidationKeys.length){
      // console.log(this.allValidationKeys, "allValidationKeys")
      for(let i = 0; i < this.allValidationKeys.length; i++){
        let eachValidationKey = this.allValidationKeys[i];
        // console.log(eachValidationKey,this.investigationQuery?.[eachValidationKey], "eachValidationKey")
        if(this.investigationQuery?.[eachValidationKey]){
          // console.log(this.investigationQuery?.[eachValidationKey], "ifTrue")
          continue;
        }else{
          // console.log(this.investigationQuery?.[eachValidationKey], "ifFalse")
          if(!this.failedValidationKeys.includes(eachValidationKey)){
            this.failedValidationKeys.push(eachValidationKey);
          }
        }
      }
    }
  }

  doAllFieldsValidations(){
    this.addConditionalValidations();
    this.doFailedValidation();
  }
  
  removeBorder(event){
    if(event){
      const index = this.failedValidationKeys?.findIndex((e) => e == "btcAddress")
      this.failedValidationKeys?.splice(index, 1)
    }
    else{
      this.failedValidationKeys?.push("btcAddress")
    }
  }

  async callWalletApi(){
    sessionStorage.removeItem('cryptoSearchResponse');
    this._data.changeLoaderVisibility(true);
    const res = await this._rs.doCryptoSearch(this.investigationQuery?.btcAddress).toPromise()
    if(res?.length){
      if(res?.length == 1){
        this.router.navigateByUrl('aml-screening/crypto-report');
        sessionStorage.setItem("cryptoSearchResponse", JSON.stringify(res?.[0]))
      }
      else{
        this.router.navigateByUrl('aml-screening/crypto-investigation-results');
        sessionStorage.setItem("cryptoSearchResponse", JSON.stringify(res))
      }
    }
    else{
      this._data.toastrShow(`No Match Found`, 'warning')
    }
    this._data.changeLoaderVisibility(false)
    sessionStorage.setItem('amlSearchQuery', JSON.stringify(this.investigationQuery));
  }

  doSearch(){
    if(this.investigationQuery?.investigationType == "Crypto"){
      if(this.investigationQuery?.btcAddress){
        this.callWalletApi();
      }
      else{
        this.failedValidationKeys?.push("btcAddress")
      }
    }
    else{
      this.investigationQuery.btcAddress = null
      this.doAllFieldsValidations();
      // console.log(this.failedValidationKeys, "failedValidationKeys")
      if(this.failedValidationKeys.length){
        // this._data.toastrShow(`Please fill this "${this.failedValidationKeys}" required fields`,'warning')
      }
      else{
        if(this.investigationQuery?.searchKey){
          this.investigationQuery.searchKey = this.investigationQuery?.searchKey.trim();
          if(this.investigationQuery.searchKey != ''){
            this.showTextValidation = false;
            this.callApi();
          }
          else{
            this.showTextValidation = true;
          }
        }
        else{
          this.showTextValidation = true;
        }
      }
    }
  }

  fileChangeListener($event) {
    // this.uploadedFile = $event[0] ? $event[0] : $event.target.files[0];
    let uploadedFile = $event[0] ? $event[0] : $event.target.files[0];
    console.log(uploadedFile, $event)
    if (uploadedFile && this.extAllowed.indexOf(uploadedFile.type) >= 0) {
      if(this.fileList.length < 3){
        this.fileList.push(uploadedFile);
      }else{
        this._data.toastrShow("Can't upload more than 3 files", "warning");
      }
    }
    else {
      this._data.toastrShow("File extension not supported , we support only png, jpg, jpge", "error");
    }
    this.attachment.nativeElement.value = '';
    this.investigationQuery.uploadedFiles = this.fileList;
  }

  removeFile(file){
    this.fileList = this.fileList.filter(e => e.name !== file?.name);
    this.investigationQuery.uploadedFiles = this.fileList;
  }

 async callApi() {
    sessionStorage.removeItem('amlSearchQuery');
    sessionStorage.removeItem('amlSearchResponse');
    sessionStorage.removeItem('entityAmlData');
    sessionStorage.removeItem('topSearch')
    if(this.investigationQuery.riskPolicy){
      const chooseData = this.riskPolicyData.filter((f) => f.id == this.investigationQuery.riskPolicy);
      if(chooseData && chooseData.length){
        this.investigationQuery.country = chooseData[0]['countries'];
        this.investigationQuery['categories'] = chooseData[0]['categories'];
        this.investigationQuery['dataSources'] = chooseData[0]['dataSources'];
      }
    }
    const payload = { 
      "size": 10, 
      "page": 0, 
      "searchType": this.investigationQuery.searchType, 
      "name": [this.investigationQuery.searchKey], 
      "country": this.investigationQuery.country ? this.investigationQuery.country : [], 
      "source": this.investigationQuery['dataSources'] ? this.investigationQuery['dataSources'] : [], 
      "guid": "", 
      "category": this.investigationQuery['categories'] ? this.investigationQuery['categories'] : [], 
      "gender": [], 
      "type": this.investigationQuery.investigationType ? [this.investigationQuery.investigationType] : []
    }
    this._data.changeLoaderVisibility(true);
    const res = await this._rs.doAmlSearch(payload).toPromise()
    this._data.changeLoaderVisibility(false)
    sessionStorage.setItem('amlSearchQuery', JSON.stringify(this.investigationQuery));
    const result = await this._rs.getAmlInvestigationHistory(payload).toPromise();
    if(res && res.content && res.content.length){
      sessionStorage.setItem('amlSearchResponse', JSON.stringify(res));
    }
    if(this.investigationQuery?.investigationType === 'Organisation' && this.investigationQuery?.country?.[0] === 'India' && this.investigationQuery?.isRegistryCheck){
      let params = {
        companyType: this.investigationQuery.companyType,
        companyId: this.investigationQuery.companyId
      }
      this.router.navigate(['aml-screening/aml-investigation-results-corp-india'],  { queryParams: params});
      if(this.investigationQuery?.companyType === 'MCA Registered Entity' && this.investigationQuery.uploadedFiles?.length){
        this.fetchBeneficialOwnersData();
      }
    }else{
      this.router.navigateByUrl('aml-screening/aml-investigation-results');
    }
  }

  viewInfoText(){
    const initialState = {
      title: 'Info',
      type: "show-aml-info-text",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }

}
