import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../../services';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../risk-screening.service';

@Component({
  selector: 'app-risk-policy-table',
  templateUrl: './risk-policy-table.component.html',
  styleUrls: ['./risk-policy-table.component.scss', '../risk-screening.component.scss']
})
export class RiskPolicyTableComponent implements OnInit {
  newPage;
  page: number = 1;
  size: number = 10;
  order: string;
  orderArray: any[] = [
    { key: 'Latest First', value: 'DESC' },
    { key: 'Oldest First', value: 'ASC' },
  ]
  tableList: any[] = [];
  totalItems: number = 0;
  totalPage: number = 0;
  itemPerPage: any[] = [
    { value : 10 },
    { value : 25 },
    { value : 50 },
  ];
  rowPageNo: number = 10;
  initialNumberElement: number = 1;
  secondElement: number = 10;
  public bsModalRef: BsModalRef;
  countryList: any[] = [];
  categoryList: any[] = [];
  datasourceList: any[] = [];
  resultsCount: number = 0;
  isHideFilters: boolean = true;
  searchKey: any;
  keywords: any[] = [];
  filterArray: any[] = [];
  filterKeyArray: any[] = [];

  constructor(private _rs: RiskScreeningService, private _data: DataService, private modalService: BsModalService) { }

  async ngOnInit() {
    this.order = 'DESC';
    this._data.changeLoaderVisibility(true);
    this.countryList = await this.getAllCountry();
    this.categoryList = await this.getAllCategory();
    this.datasourceList = await this.getAllDataSource();
    this._data.changeLoaderVisibility(false);
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, true);
  }

  getAllCountry(){
    const res = this._rs.getAllCountries().toPromise();
    return res || [];
  }

  getAllCategory(){
    const res = this._rs.getAllCategories().toPromise();
    return res || [];
  }

  getAllDataSource(){
    const res = this._rs.getAllDataSourceList().toPromise();
    return res || [];
  }

  getTableData(page, size, order, filterKeyArray, searchFilterCalled?: boolean){
    const payload = {
      "size": size, 
      "page": page,
      "order": order,
      "country": [],
      "source": [],
      "category": [],
      "filterName": this.searchKey
    }
    for(let item of filterKeyArray){
      payload[item.key] = item.value;
    }
    this._data.changeLoaderVisibility(true);
    this._rs.postRiskPolicyData(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      this.tableList = res['content'] || [];
      this.totalItems = res['totalElements'];
      this.totalPage = res?.totalPages;
      if(searchFilterCalled){
        this.getFilterData();
        this.resultsCount = res['totalElements'];
      }
      this.initialNumberElement = page * size + 1;
      if (this.initialNumberElement + size > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.initialNumberElement + size - 1;
      }
    });
  }

   // Expand Filter

   getIndexValueOfApplyFilters(i) {
    if (this.filterArray.indexOf(i) > -1) {
      this.filterArray.splice(this.filterArray.indexOf(i), 1);
    } else {
      this.filterArray.push(i);
    }
  }

  applyAllFilter(index) {
    this.filterKeyArray = [];
    for (var i = 0; this.keywords.length > i; i++) {
      let obj = { key: '', value: [] }
      obj['key'] = this.keywords[i].heading;
      if (i == index) {
        this.keywords[i].filters.map((e) => e.value = this.keywords[i].value );
      }
      this.keywords[i].filters.forEach((item) => {
        if (item.value) {
          obj['value'].push(item.key);
        }
      });
      this.filterKeyArray.push(obj);
    }
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  getFilterData(){
    this._data.changeLoaderVisibility(true);
    this._rs.riskPolicyFilterData().pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if(res['data']){
        this.keywords = [];
        this.filterArray = [];
        for(let key of Object.keys(res['data'])){
            let keywordsObj = { heading: key , value: false, filters: [] }
            for(let element of res['data'][key]){
              if(element.key != ''){
                let filterObj = { key: element.key, value: false, keyName: "", doc_count: 0 };
                if(element.key.includes('//')){
                  const splitKey = element.key.split('//');
                  filterObj['keyName'] = splitKey[1];
                }
                else{
                  filterObj['keyName'] = element.key;
                }
                filterObj['doc_count'] = element.doc_count;
                keywordsObj['filters'].push(filterObj)
            }
          }
          this.keywords.push(keywordsObj);
        }
      }
    });
  }

  applyFilter() {
    this.filterKeyArray = [];
    for(let e of this.keywords){
      let obj = { key: '', value: [] }
      obj['key'] = e.heading;
      e.filters.forEach((item) => {
        if (item.value) {
          obj['value'].push(item.key);
        }
      });
      this.filterKeyArray.push(obj);
    }
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  doSearch(){
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  orderFilter(event){
    this.order = event;
    this.page = 1;
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, true);
  }

  clearAllFilter(){
    this.filterKeyArray = [];
    this.searchKey = null;
    this.keywords.forEach((e) => {e.value = false; e.filter = e.filters.map((m) => m.value = false)});
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  pageChange($event) {
    if($event <= 0 || $event > this.totalPage){
      this._data.toastrShow(`${$event <= 0 ? 'Page number cannot be less than 1' : 'You have exceeded the page number'}`, 'warning');
    }
    else{
      this.page = $event;
      this.initialNumberElement = (this.page * this.rowPageNo) + 1 - this.rowPageNo
      if (this.page * this.rowPageNo > this.totalItems) {
        this.secondElement = this.totalItems
      }
      else {
        this.secondElement = this.page * this.rowPageNo;
      }
      this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
    }
  }

  rowPageSet(rowPageNo) {
    this.rowPageNo = Number(rowPageNo);
    this.page = 1;
    this.initialNumberElement = 1;
    this.secondElement = this.initialNumberElement + this.rowPageNo - 1
    this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
  }

  addNewRiskPolicy(){
    const initialState = {
      title: 'New Risk Policy',
      type: "add-new-risk-policy-category",
      riskPolicyData: {
        countries : this.countryList,
        categories : this.categoryList,
        datasourceList : this.datasourceList
      }
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        let payload = {
          "filterName": result.policyName,
          "categories": [],
          "countries": [],
          "dataSources": result.datasource,
          "description": result.description
        }
        payload['categories'] = result.categories.map((e) => (e.categoryName));
        payload['countries'] = result.countries.map((e) => (e.countryName))
        this._data.changeLoaderVisibility(true);
        this._rs.createRiskPolicy(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
          this._data.toastrShow(`Risk Policy has been created`, 'success');
          this.page = 1;
          this.filterKeyArray = [];
          this.searchKey = null;
          this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, true);
        })
      }
    });
  }

  editPolicy(item){
    const datasourceArray = this.datasourceList.map((e) => ({ ...e, isTick : item.dataSources.includes(e.dataSourceName) }));
    const initialState = {
      title: 'Edit Risk Policy',
      type: "add-new-risk-policy-category",
      riskPolicyData: {
        countries : this.countryList,
        categories : this.categoryList,
        datasourceList : datasourceArray
      },
      addNewRiskPolicyData : {
        countries : item.countries,
        categories : item.categories,
        datasource : [],
        policyName : item.filterName,
        description : item.description,
      }
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        let payload = {
          "filterName": result.policyName,
          "categories": result.categories,
          "countries": result.countries,
          "dataSources": result.datasource,
          "description": result.description
        }
        payload['categories'] = result.categories.map((e) => (e.categoryName));
        payload['countries'] = result.countries.map((e) => (e.countryName))
        this._data.changeLoaderVisibility(true);
        this._rs.updateRiskPolicy(item.id, payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
          this._data.toastrShow(`Risk Policy has been updated`, 'success');
          this.page = 1;
          this.getTableData(this.page - 1, this.rowPageNo, this.order, this.filterKeyArray, false);
        })
      }
    });
  }

  viewList(data, title){
    const initialState = {
      title: title,
      type: "data-listing",
      viewListData: data
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }

}
