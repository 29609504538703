import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { AppService, DataService } from '../../../services';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import { RelationData } from '../../../utils/aml-search';
import { AmlChecksService } from '../../aml-search/aml-checks.service';
import { RiskScreeningService } from '../risk-screening.service';

@Component({
  selector: 'app-risk-entity-report-corp-india',
  templateUrl: './risk-entity-report-corp-india.component.html',
  styleUrls: ['./risk-entity-report-corp-india.component.scss', '../risk-screening.component.scss']
})
export class RiskEntityReportCorpIndiaComponent implements OnInit {
  entityAmlData: any;
  entityMasterData: any;
  countryObject: any;
  sticky: boolean = false;
  tabs: any[] = [
    {
      heading: 'Summary',
      slug: 'summary'
    },
    {
      heading: 'Source of risk',
      slug: 'source-of-risk'
    },
    {
      heading: 'Relationship and connection',
      slug: 'relationship-and-connection'
    },
    {
      heading: 'Additional Info',
      slug: 'additional-Info'
    },
    {
      heading: 'Adverse Media Screening',
      slug: 'adverse-Media-Screening'
    }
  ];
  activeTabs: string = 'summary';
  riskProfileData: any;
  amlArray: any[] = [];
  RelationData = RelationData;
  newnodes;
  corpAmlUseArray: any[] = [];
  AdverseMediaScreeningPage: number = 0;
  categoryList = [{ "slug": "pep", "name": "PEP", "value": false, entity: "Individual" }, { "slug": "crime", "name": "Crime", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sanction", "name": "Sanction", "value": false, entity: "Individual,Organisation,Entity" }, { "slug": "watchlist", "name": "Watchlist", "value": false, entity: "Individual,Organisation,Entity" },
  { "slug": "sip", "name": "SIP", "value": false, entity: "Individual" }, { "slug": "sie", "name": "SIE", "value": false, entity: "Organisation,Entity" },
  { "slug": "si", "name": "SI", value: false, entity: "Individual,Organisation,Entity" }
  ];
  public bsModalRef: BsModalRef;
  riskScreeningReport = {
    flagedArticle: false,
    entitySummary: false,
    entityDetails: false,
    amlScreening: false,
    networkAndRelationShip: false,
    incluseAdverseMedia: false,
    filterOutMarkFalsePositive: false,
    showCrimeRecord: false,
    showSanctionRecord: false,
    showPepRecord: false,
    showSources: false
  };
  possibleMatches: any[] = [];
  currentTheme: any = 'theme-light';

  // relation graph variables ---->
  displayGraph = false
  chartOptions = {
    chart: {
      type: 'networkgraph',
      backgroundColor: '#ffffff00',
    },

    title: {
      text: '',
    },
    plotOptions: {
      networkgraph: {
        layoutAlgorithm: {
          enableSimulation: true,
          integration: 'verlet',
          linkLength: 200
        },
      },
    },
    series: [
      {
        link: {
          width: 2,
        },
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              window.open("aml-screening/risk-individual-report/" + this.series.points.find(e => e.to === this.id).guid);
            }
          },
        },
        marker: {
          radius: 20,
        },
        dataLabels: {
          enabled: true,
          style: {
            textOverflow: 'ellipsis',
            color: '#98a6ad',
            textOutline: '#00000000',
          },
          // textPath: {
          //   enabled: true,
          //   attributes: {
          //     dy: 0,

          //   }
          // },
          linkFormat: '{point.parentRelation}'
        },
        data: [],
        nodes: [],
      },
    ],

  }
  topData;
  detailedGraph: boolean = false
  relationGraphExists: boolean = true
  constructor(private _rs: RiskScreeningService, private route: Router, private _data: DataService,
    private _actRoute: ActivatedRoute, private modalService: BsModalService, private _app: AppService, private amlService: AmlChecksService) { }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    windowScroll > 14 ? this.sticky = true : this.sticky = false;
  }

  async ngOnInit() {
    this._app.currentTheme.subscribe(theme => {
      this.currentTheme = theme;
    });
    this._actRoute.params.subscribe(async ({ guid }) => {
      let amlData;
      this.topData = JSON.parse(sessionStorage.getItem('topData'))
      if (guid) {
        this._data.changeLoaderVisibility(true);
        const res = await this._rs.getDetailedRecord(guid).toPromise()
        if (res['data'] && res['data']['content']) {
          const amlResponse = res['data']['content'][0];
          amlData = JSON.stringify(amlResponse);
          await this.getData(amlData);
        }
        if(!this.topData){
          if (this.amlArray.length > 0) {
            let countrytemp = (this.entityMasterData.countryOfCitizenship && this.entityMasterData.countryOfCitizenship[0]) || (this.entityMasterData.nationality && this.entityMasterData.nationality[0]);
            try {
              for (let i = 0; i < this.amlArray.length; i++) {
                this._data.changeLoaderVisibility(true);
                await this.getTopDataSourcesAml(this.amlArray[i].dataSource, this.amlArray[i].categoryName, countrytemp);
                this._data.changeLoaderVisibility(false);
              }
              sessionStorage.setItem('topData',JSON.stringify(this.topData))
            } catch (error) {
              console.error("Error in sequential API calls:", error);
            }
          }
        }
        this._data.changeLoaderVisibility(false);
      }
      else if (sessionStorage.getItem('entityAmlData')) {
        amlData = sessionStorage.getItem('entityAmlData');
        await this.getData(amlData);
      }
      else {
        this.route.navigate(['/aml-screening/dashboard'], {
          replaceUrl: true,
        });
      }
      await this.getReportModificationReport()
    })
  }

  async getTopDataSourcesAml(datasource, category, country) {
    let payload = {
      category: category.toLowerCase(),
      country: country,
      global: category.toLowerCase() === "sanction" ? true : false,
    };
  
    try {
      let response = await this._rs.getTopDataSource(payload).toPromise();
  
      if (response && (response.length === 0 || response === null)) {
        payload.global = true;
        response = await this._rs.getTopDataSource(payload).toPromise();
      }
  
      if (response) {
        const Data = await this.updateFoundStatus(response, datasource);
        this.topData.push(Data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  async updateFoundStatus(Data, dataSource): Promise<void> {
    const response = JSON.parse(dataSource);
    for (const e of Data) {
      try {
        let foundStatus = response
          .map((item) => item.toLowerCase())
          .includes(e.name.toLowerCase());
        e["found"] = foundStatus;
      } catch (error) {
        console.error("Error updating found status:", error);
      }
    }
    return Data
  }
  async getReportModificationReport() {
    const response: any = this._rs.getReportModificationReport().toPromise();
    if (response) this.riskScreeningReport = response
  }

  async getData(amlData) {
    this.entityAmlData = JSON.parse(amlData);
    if (this.entityAmlData.masterData) {
      this.entityMasterData = this.entityAmlData.masterData;
    }
    if (this.entityAmlData.guid) {
      await this.getCrrData(this.entityAmlData.guid);
      // this.getRelationGraphData()
    }
    if (this.entityAmlData.relationshipList && this.entityAmlData.relationshipList.length) {
      await this.createGraph(this.entityAmlData.relationshipList)
    }
    let country;
    if (this.entityMasterData.nationality) {
      country = this.entityMasterData.nationality[0];
    }
    else if (this.entityMasterData.countryOfCitizenship) {
      country = this.entityMasterData.countryOfCitizenship[0];
    }
    await this.getCountryObject(country);
    this.categoryList = this.categoryList.filter((e) => e.entity.includes(this.entityMasterData.entityType));

    // Aml Data
    if (this.entityAmlData.details && this.entityAmlData.details.length) {
      this.amlArray = this.categoryList.map((e) => (
        {
          categoryName: e.name, entityType: this.entityMasterData.entityType,
          details: this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1)
            .map((m) => ({ ...m, dateAdded: this.entityAmlData.recordCreatedDate, lastModified: this.entityAmlData.recordLastModifiedDate })),
          dataSource: JSON.stringify(this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1)
            .map((m) => m.datasource && m.datasource.name)),
          label: this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1).length ? this._rs.getTagValue(e.name) : 'No Risk',
          color: this.entityAmlData.details.filter((f) => f.category.findIndex((i) => i.slug === e.slug) != -1).length ? this._rs.getTagColor(e.name) : 'No Risk',
        }
      ));
      for (var j = 0; this.amlArray.length > j; j++) {
        this.getIndexValueForCorpAML(j);
      }
    }

    // Possible Matches Data

    if (this.entityAmlData && this.entityAmlData.possibleMatches && this.entityAmlData.possibleMatches.length > 0) {
      this.entityAmlData.possibleMatches.forEach(match => {
        let matchedEntity = {}
        let matchScore = {}
        if (match.attributes_score.length > 0) {
          match.attributes_score.forEach(element => {
            matchedEntity[element.key] = element.candidate_value;
            matchScore[element.key] = element.score
          });
        }
        matchedEntity["aggrigated_score"] = match.aggrigated_score;
        matchScore["aggrigated_score"] = match.aggrigated_score;
        matchedEntity["candidateGuid"] = match.candidateGuid;
        matchedEntity["scores"] = matchScore;
        this.possibleMatches.push(matchedEntity);
      });
    }
  }

  // Create Graph

  createGraph(list) {
    let levela = [];
    let levelb = [];
    let levelc = [{ "name": this.entityMasterData.fullName[0], title: "Entity", cssClass: "text-selected-entity", guid: "" }];
    let leveld = [];
    for (let i = 0; i < list.length; i++) {
      let obj = { "name": list[i].fullName, title: list[i].parentRelation, cssClass: "text-entity", guid: list[i].childGuid }
      if (this.RelationData[list[i].parentRelation] == 2) {
        levela.push(obj)
      }
      if (this.RelationData[list[i].parentRelation] == 1) {
        levelb.push(obj)
      }
      if (this.RelationData[list[i].parentRelation] == 0) {
        levelc.push(obj)
      }
      if (this.RelationData[list[i].parentRelation] == -1) {
        leveld.push(obj)
      }
    }
    levelc[0]["childs"] = leveld;
    if (levelb.length > 0) {
      levelb[0]["childs"] = levelc
    }
    else {
      levelb[0] = { "name": "", childs: levelc }
    }
    if (levela.length > 0) {
      levela[0]["childs"] = levelb
    }
    else {
      levela[0] = { "name": "Relationship Graph", childs: levelb }
    }
    this.newnodes = levela;
  }

  async getCountryObject(country) {
    if (country) {
      const res = await this._rs.getCountryDetailsByCountry(country).toPromise();
      this.countryObject = res['data'];
    }

    // Create AMl Investigation
    if (this.entityMasterData) {
      // called at aml-result page instead of here
      //  await this.createAmlInvestigation();
    }
  }

  async createAmlInvestigation() {
    const payload = {
      "entityName": this.entityMasterData.fullName[0],
      "countryName": this.countryObject?.countryName || null,
      "entityCategoryList": this.entityMasterData.category.map((e) => e.name),
      "entityGuid": this.entityAmlData.guid,
      "entityType": this.entityMasterData.entityType
    }
    await this._rs.creatAmlInvestigation(payload).toPromise();
  }

  bookmarkedEntity() {
    const payload = {
      "fullName": this.entityMasterData.fullName[0],
      "guid": this.entityAmlData.guid,
      "entityType": this.entityMasterData.entityType
    }
    this._data.changeLoaderVisibility(true);
    this._rs.bookmarkedEntity(payload).pipe(finalize(() => {
      this._data.changeLoaderVisibility(false);
    })).subscribe(() => this._data.toastrShow('Entity Bookmarked', 'success'));
  }

  scheduledChecks() {
    const initialState = {
      title: 'Schedule Check',
      type: "risk-screening-scheduled-checks",
      categoryList: this.entityMasterData.category
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (Object.keys(result).length) {
        // const startDate = new DatePipe('en-Us').transform(result.startDate, 'yyyy-MM-dd HH:mm:ss');
        const payload = {
          "entityName": this.entityMasterData.fullName[0],
          "entityType": this.entityMasterData.entityType,
          "entityGuid": this.entityAmlData.guid,
          "scheduledIntervalToCheck": result.interval,
          "categoryCheck": result.category,
          "customDate": result.startDate
        }
        this._data.changeLoaderVisibility(true);
        this._rs.scheduleChecks(payload).pipe(finalize(() => {
          this._data.changeLoaderVisibility(false);
        })).subscribe(() => this._data.toastrShow('Checks has been scheduled', 'success'));
      }
    });
  }

  onClickGenerateReport() {
    const initialState = {
      type: "risk-screening-report",
      title: "Report",
      riskScreeningReport: this.riskScreeningReport
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(data => {

      if (Object.keys(data).length) {
        this.downloadReport(data)
      }
    });

  }

  async getCrrData(guid) {
    const payload = {
      guid: guid
    }
    this._data.changeLoaderVisibility(true);
    const res = await this._rs.getAdvMediaResults(payload).toPromise()
    this._data.changeLoaderVisibility(false)
    if (res['data'] && res['data']['content'] && res['data']['content'].length) {
      this.riskProfileData = res['data']['content'][0].riskProfile;
    }
  }

  // Open Entity Report in new tab using guid

  openEntity(guid) {
    if (guid) window.open(`aml-screening/risk-individual-report/${guid}`);
  }

  downloadReport(data) {

    this._data.changeLoaderVisibility(true);
    this._rs.reportModification(data).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
      this._data.changeLoaderVisibility(true)
      // need to check the payload
      let payload = {
        "guid": this.entityAmlData.guid,
        "name": this.entityMasterData.fullName[0],
        "countryCode": this.countryObject ? this.countryObject.countryCode : ""
      }
      this._rs.downloadIndividualReport(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
        if (res.size < 80) {
          this._data.toastrShow(`Insufficient Credits`, 'error');
        }
        else {
          const blob = new Blob([res], { type: 'application/pdf' });
          FileSaver.saveAs(blob, `${this.entityMasterData.fullName[0]}-report.pdf`);
        }
      })
    })
  }

  // For Auto Expand

  getIndexValueForCorpAML(i) {
    if (this.corpAmlUseArray.indexOf(i) > -1) {
      this.corpAmlUseArray.splice(this.corpAmlUseArray.indexOf(i), 1)
    }
    else {
      this.corpAmlUseArray.push(i)
    }
  }

  selectedTabs(slug: string) {
    this.goToTabs(slug);
    this.activeTabs = slug;
  }

  goToTabs(div) {
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(div)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    if (this.activeTabs != div) {
      let scrolly = topOfElement - 250;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else {
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  // Read More and Read Less

  expand(id, action) {
    if (action == 'expand') {
      var element = document.getElementById(id);
      element.classList.add('d-none');
      element.classList.remove('d-inline')
      var expelement = document.getElementById(id + '-expand')
      expelement.classList.add('d-inline')
      expelement.classList.remove('d-none')
    }
    else {
      var element = document.getElementById(id);
      element.classList.remove('d-none');
      element.classList.add('d-inline');
      var expelement = document.getElementById(id + '-expand');
      expelement.classList.remove('d-inline')
      expelement.classList.add('d-none')
    }
  }

  goToDashboard() {
    this.route.navigate(['/aml-screening/dashboard'], {
      replaceUrl: true,
    });
    sessionStorage.removeItem('entityAmlData');
    sessionStorage.removeItem('amlSearchResponse')
  }
  // graph fx =======>
  getRelationGraphData() {
    this.displayGraph = false
    this.amlService.getRelationGraphData(this.entityAmlData.guid).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res => {
      this.formatRelationGraphData(res.data)

    })
  }
  formatRelationGraphData(data) {
    if (data.length) {
      let arr = []
      const rootGuids = this.entityAmlData.details.map(e => e.guid)
      data.forEach(e => {
        const index = data.findIndex(p => p.childGuid === e.parentGuid)
        if (rootGuids.includes(e.parentGuid)) {
          arr.push({
            from: this.entityAmlData.masterData.fullName[0],
            to: `${e.fullName}`,
            parentRelation: e.parentRelation,
            childRelation: e.childRelation,
            guid: e.childGuid
          })
        }
        else if (index !== -1 && this.detailedGraph) {
          arr.push({
            from: `${data[index].fullName}`,
            to: e.fullName,
            parentRelation: e.parentRelation,
            childRelation: e.childRelation,
            guid: e.childGuid
          })
        }
      });
      this.chartOptions.series[0].data = arr

      const arrtemp = new Set(data.filter(e => (rootGuids.includes(e.parentGuid) || this.detailedGraph)).map(e => e.fullName))
      let nodesArr = Array.from(arrtemp);
      nodesArr = nodesArr.map(e => ({
        id: e,
        marker: {
          radius: 14,
          fillColor: '#17b18c'
        }
      }))
      this.chartOptions.series[0].nodes = [{
        id: this.entityAmlData.masterData.fullName[0],
        marker: {
          radius: 28,
          fillColor: '#ed7c15'
        },
      }, ...nodesArr]
      this.displayGraph = true
      this.chartOptions.plotOptions.networkgraph.layoutAlgorithm.linkLength = arr.length > 50 ? 100 : 200
    }
    else {
      this.relationGraphExists = false
    }

  }
  handleRelationData(type: 'zoom-in' | 'zoom-out') {
    if (this.detailedGraph !== (type === 'zoom-in')) {
      this.detailedGraph = (type === 'zoom-in')
      this.getRelationGraphData()
    }

  }
}

