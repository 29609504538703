import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class RiskScreeningService {
  token: any;

  constructor(
    private http: HttpClient,
    @Inject("API_URL") public API_URL: any
  ) {
  }
  getTagValue(tag) {
    switch (tag.toLowerCase()) {

      case 'si':
      case 'sip':
      case 'sie':
        return 'Low'

      case 'pep':
        return 'Medium-Low'

      case 'watchlist':
        return 'Medium-High'

      case 'crime':
        return 'High'

      case 'sanction':
        return 'Very High'

      default:
        return
    }
  }
  getTagColor(tag) {
    switch (tag.toLowerCase()) {

      case 'si':
      case 'sip':
      case 'sie':
        return '#17b18c'

      case 'pep':
        return '#feb548'

      case 'watchlist':
        return '#f97d37'

      case 'crime':
        return '#eb513c'

      case 'sanction':
        return '#e12828'

      default:
        return
    }
  }
  getLocationWiseRiskChanges() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/alerts/widget/by-country`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getRelationGraphData(id){
    return this.http.get(`${this.API_URL}/v2/aml/rca/${id}`).pipe(map((res: any) => res.data)); 
   }
   getRelationGraphDataImmediate(id){
     return this.http.get(`${this.API_URL}/v2/aml/immediate/rca/${id}`).pipe(map((res: any) => res.data)); 
    }
  getAmlChangesBasedOnCategories() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/alerts/widget/by-category`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getRiskChangesByDuration() {
    return this.http.get<any>(`${this.API_URL}/kys/v2.0/alerts/widget/category/by-date`).pipe(
      map(res => {
        return res;
      })
    );
  }
  getIntermediateActionReqList(data) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/bookmarked/get-alerts-data`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  getTopSearchList(data) {
    return this.http.post<any>(`${this.API_URL}/aml/investigation/get`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  getTopSearchCountList(data) {
    return this.http.post<any>(`${this.API_URL}/aml/investigation/get-history`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  getAmlInvestigationHistory(data) {
    return this.http.post<any>(`${this.API_URL}/aml/investigation/add/history`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  creatAmlInvestigation(data) {
    console.log('-==>>','helloooo')
    return this.http.post<any>(`${this.API_URL}/aml/investigation/create`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  bookmarkedEntity(data) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/bookmark-entity`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  scheduleChecks(data) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/aml/alert-bookmark/add-schedule-interval`, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  getRiskDashboardTabelData(payload) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/bookmarked/get-alerts-data`, payload).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  getRiskDashboardAmlFilters(payload) {
    return this.http.post<any>(`${this.API_URL}/kychub/aml-alerts/filter`, payload).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  getRiskDashboardAlertCount() {
    return this.http.get<any>(`${this.API_URL}/kychub/aml-alerts/fetch/alerts-count`).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  getRiskDashboardPendingSlaAlertCount(payload) {
    return this.http.post<any>(`${this.API_URL}/kychub/aml-alerts/fetch/pending-sla-alerts`, payload).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  getRiskDashboardReportData(id: string) {
    return this.http.get<any>(`${this.API_URL}/kychub/aml-alerts/fetch/${id}`).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  markAsSeenByIdRiskDashboardTable(id: string, payload) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/bookmarked/notification-seen/${id}`, payload).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  markAsFalsePositiveByIdRiskDashboardTable(payload) {
    return this.http.post<any>(`${this.API_URL}/kys/v2.0/alerts/add-false-positive-data`, payload).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  markAsFalsePositiveById(payload, id) {
    return this.http.post<any>(`${this.API_URL}/kychub/aml-alerts/update/alert-status/${id}`, payload).pipe(
      map(res => {
        return res['data'];
      })
    );
  }
  getAdvMediaResults(obj) {
    return this.http.post<any>(`${this.API_URL}/v2/crr-data/search/`, obj).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getCountryDetailsByCountry(country) {
    return this.http.get<any>(`${this.API_URL}/country/get-by/country-name/${country}`).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getNewAdverseArticles(obj) {
    return this.http.post<any>(`${this.API_URL}/adversemedia/get-articles`, obj).pipe(
      map((res) => {
        return res['data'];
      })
    );
  }
  doAmlSearch(obj) {
    return this.http.post<any>(`${this.API_URL}/v2/aml/new-search`, obj).pipe(
        map((res) => {
            return res['data'];
        })
    );
  }
  doCryptoSearch(address) {
    return this.http.get<any>(`${this.API_URL}/crypto-screening-lite/screening/wallet-profile?address=${address}`).pipe(
        map((res) => {
            return res['data'];
        })
    );
  }
  doCryptoTransactionsSearch(address, page, size) {
    return this.http.get<any>(`${this.API_URL}/crypto-screening-lite/screening/wallet-transactions?address=${address}&page=${page}&size=${size}`).pipe(
        map((res) => {
            return res['data'];
        })
    );
  }
  getSearchFilters(dataQuery) {
    return this.http.post<any>(`${this.API_URL}/v2/aml/filter`, dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }
  getRecentArticles(payload) {
    return this.http.post<any>(`${this.API_URL}/v2/adversemedia/news`, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getArticleScore(payload) {
    return this.http.post<any>(`${this.API_URL}/v2/adversemedia/entity-score`, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getArticleNetwrokGraph(payload) {
    return this.http.post<any>(`${this.API_URL}/v2/adversemedia/entity-graph`, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getTopDataSource(payload){
    return this.http.post<any>(`${this.API_URL}/aml/dynamic-search`, payload).pipe(
      map(res => {
        return res['data'];
      })
    );
   }
   getRegistryData(payload){
    return this.http.post<any>(`${this.API_URL}/corporate/uk/search`, payload).pipe(
      map(res => {
        return res;
      })
    );
   }
   getAllCountriesForDynamicSearch(){
    return this.http.get<any>(`${this.API_URL}/aml/dynamic-search/get-countries`).pipe(
      map((res) => {
        return res;
      })
    );
   }
  getTrendingNews(payload) {
    return this.http.post<any>(`${this.API_URL}/v1.0/news/search`, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
 getDashboardStatsDaily(){
  return this.http.get<any>(`${this.API_URL}/fincai/analytics/daily-update`).pipe(
    map((res) => {
      return res;
    })
  );
 }
 getSubCategoryData(payload: Object){
  return this.http.post<any>(`${this.API_URL}/v2/aml/sub-category-filter`, payload).pipe(
    map((res) => {
      return res['data'];
    })
  );
 }
 getDashboardStatsOverall(){
  return this.http.get<any>(`${this.API_URL}/fincai/analytics/overall-update`).pipe(
    map((res) => {
      return res;
    })
  );
 }
 adverseMediaMarkAsFalsePositive(payload){
  return this.http.post<any>(`${this.API_URL}/kys/v2.0/ame/create/false-positive`, payload).pipe(
    map((res) => {
      return res;
    })
  );
 }
 getDetailedRecord(guid) {
  return this.http.get<any>(`${this.API_URL}/v2/aml/search/${guid}`).pipe(
    map(res => {
      return res;
    })
  );
 }
 getRiskPolicyData() {
  return this.http.get<any>(`${this.API_URL}/aml/filter/template/get`).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 postRiskPolicyData(payload: Object) {
  return this.http.post<any>(`${this.API_URL}/aml/filter/template/get`, payload).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 riskPolicyFilterData() {
  return this.http.get<any>(`${this.API_URL}/aml/filter/template/get/count`).pipe(
    map(res => {
      return res;
    })
  );
 }
 getAllCountries() {
  return this.http.get<any>(`${this.API_URL}/country/all`).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 getAllCategories() {
  return this.http.get<any>(`${this.API_URL}/aml/categories/get`).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 getAllDataSourceList() {
  return this.http.get<any>(`${this.API_URL}/aml/data-source/get`).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 createRiskPolicy(payload) {
  return this.http.post<any>(`${this.API_URL}/aml/filter/template/create`, payload).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 updateRiskPolicy(id: string, payload) {
  return this.http.post<any>(`${this.API_URL}/aml/filter/template/update/${id}`, payload).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 getGlobalSanctionNews(payload){
  return this.http.post<any>(`${this.API_URL}/v1.0/news/today`, payload).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 getBlackListedperson(payload) {
  return this.http.post<any>(`${this.API_URL}/client/black-list/get-entities`, payload).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 getScheduledChecksList(payload) {
  return this.http.post<any>(`${this.API_URL}/kys/v2.0/aml/schedule/filter`, payload).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 locationWiseChartList() {
  return this.http.get<any>(`${this.API_URL}/aml/investigation/widget/by-country`).pipe(
    map(res => {
      return res['data'];
    })
  );
 }
 reportModification(payload) {
  return this.http.post<any>(`${this.API_URL}/kyc/aml/v1/report/config/add`, payload).pipe(
    map((res) => {
      return res;
    })
  );
}
downloadIndividualReport(payload){
  return this.http.post(`${this.API_URL}/kys/v2.0/aml/report`,payload,{responseType:"blob"}).pipe(map((res: any) => res));
}
getReportModificationReport() {
  return this.http.get<any>(`${this.API_URL}/kyc/aml/v1/report/config/get` ).pipe(
    map((res) => {
      return res["data"];
    })
  );
}
getDashboardChartsData(){
  return this.http.get<any>(`${this.API_URL}/kychub/aml-alerts/fetch-widgets/MONTH` ).pipe(
    map((res) => {
      return res["data"];
    })
  );
  
}
googleAmlSearchByName(entityName){
  return this.http.get<any>(`${this.API_URL}/google/search/${entityName}` ).pipe(
    map((res) => {
      return res["data"];
    })
  );
}
pepAnalysisAmlSearchByName(entityName){
  return this.http.get<any>(`${this.API_URL}/adversemedia/screening/fetch/${entityName}` ).pipe(
    map((res) => {
      return res["data"];
    })
  );
}
maltaCitizenSearchByName(entityName){
  return this.http.get<any>(`${this.API_URL}/miscellaneous/search/malta/${entityName}` ).pipe(
    map((res) => {
      return res["data"];
    })
  );
}
fetchCinData(payload){
  return this.http.post<any>(`${this.API_URL}/kyc/india/v2/cin-simple-search`, payload).pipe(
    map((res) => {
      return res;
    })
  );
}

}

